import { configureStore,combineReducers } from '@reduxjs/toolkit'
import loaderReducer from '../slice/loader.slice';
import imageThumbReducer from "../slice/imagethumb.slice";
import sidebarReducer from "../slice/sidebarSlice";
import providerReducer from "../slice/providerSlice";

import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore, FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER, } from 'redux-persist';

const persistConfig = {
  key: "root",
  version: 1,
  storage,
};

const reducers = combineReducers({
  loader: loaderReducer,
  provider : providerReducer,
  sidebar: sidebarReducer,
  imageThumbSlice : imageThumbReducer,
})

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer:persistedReducer,
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware({
      serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
  }),
  devTools: process.env.NODE_ENV !== 'production',
})

export let persister = persistStore(store);
