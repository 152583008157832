import React from "react";
import FullpageLoader from "../components/FullPageLoader";
import { useSelector } from "react-redux";

const PublicLayout = (props) => {
  const fullPageLoader = useSelector((data) => data.loader.fullPageLoader);

  return (
    <>
     <div className="main-wrapper">
        {fullPageLoader && <FullpageLoader />}
        <div className="main-content">
        {props.children}
        </div>
      </div>
    </>
  )
}

export default PublicLayout