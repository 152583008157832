import { createSlice } from "@reduxjs/toolkit";

const loaderSlice = createSlice({
    name : "loader",
    initialState: {
        loading: false,
        fullPageLoader : false,
    },
    reducers: {
        loadingStart: (state) => {
            state.loading = true;
        },
        loadingStop : (state) => {
            state.loading = false;
        },
        fullPageStart: (state) => {
            state.fullPageLoader = true;
        },
        fullPageStop : (state) => {
            state.fullPageLoader = false;
        },

    },
})

export const { loadingStart, loadingStop,fullPageStart,fullPageStop } = loaderSlice.actions;
export default loaderSlice.reducer;
