import React from 'react'
import pageNotFound from "../../assets/images/pagenfo.jpg";

function NotFound() {
  return (
    <div className="AddAgent main-parent">
       <div className="inner-wrapper">
       <div className="add-wrapper">
       <div className="d-flex align-items-center justify-content-center vh-100">
            <div className="text-center row">
                <div className=" col-md-6">
                    <img src={pageNotFound} alt="pageNotFound"
                        className="img-fluid"/>
                </div>
                <div className=" col-md-6 mt-5">
                    <p className="fs-3"> <span className="text-danger">Sorry!</span></p>
                    <p className="lead">
                        The page you’re looking for doesn’t exist.
                    </p>
                    <a href="/" className="btn btn-primary">Go Home</a>
                </div>

            </div>
        </div>
       </div>
       </div>
    </div>
  )
}

export default NotFound