export const pagination = {
  page: 1,
  limit: 10,
  maxlimit: 10000,
  scrollLimit: 12,
};

export const PaginationOption = [
  10, 15, 20, 25
];
