import React from "react";
import { Provider } from "react-redux";
import { createRoot } from "react-dom/client";
import { PersistGate } from "redux-persist/integration/react";
import { store, persister } from "./store/";
import App from "./App";
// import TagManager from 'react-gtm-module';

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./assets/scss/style.scss";
import "react-toastify/dist/ReactToastify.css";

//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";
        
const rootElement = document.getElementById("root");
// const tagManagerArgs = {
//   gtmId: 'GTM-53WJWVS'
// }

// TagManager.initialize(tagManagerArgs)

createRoot(rootElement).render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persister}>
      <App />
    </PersistGate>
  </Provider>
);