
import React from "react";
import PublicLayout from "../HoC/PublicLayout";
import OnlineScheduler from "../pages/OnlineScheduler";
import ViewScheduler from "../pages/OnlineScheduler/ViewScheduler";
import PatientDetails from "../pages/OnlineScheduler/PatientDetails";
import referral from "../pages/referral";

// Public routes
export const publicRoutes = [
  {
    path: "/scheduler",
    layout: PublicLayout,
    component: OnlineScheduler,
  } ,
  {
    path: "/view-scheduler",
    layout: PublicLayout,
    component: ViewScheduler,
  },
  {
    path: "/view-scheduler/success",
    layout: PublicLayout,
    component: PatientDetails,
  },
  {
    path: "/referral",
    layout: PublicLayout,
    component: referral,
  }
];
