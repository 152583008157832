import React from 'react'
import "./loader.css"

function FullpageLoader() {
  return (
    <div className="loader-wrapper">
      <div className="loader4">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
      </div>
    </div>
  )
}

export default FullpageLoader