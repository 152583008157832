import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  updateOnlineSchedulerPatient,
  ListOfInsuranceCarriers,
  AddPatientInsuranceDetails,
  createIAmNotPatient,
} from "../../services/onlinescheduler.service";
import { useDispatch } from "react-redux";
import { Dropdown } from "primereact/dropdown";
import { pagination } from "../../utils/pagination";
import { Skeleton } from "primereact/skeleton";
import { toast } from "react-toastify";
import { setIn } from "formik";
import moment from "moment";

const PatientDetails = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    address: "",
    city: "",
    state: "",
    zip: "",
    subscribernum: "",
    grpnum: "",
    hearAbtUs: "",
    otherOption: "",
    optionForRecFamily: "",
    optionForPractioner: "",
  });
  const apiUrlString = localStorage.getItem("schedulerUrl");
  const apiUrl = apiUrlString ? JSON.parse(apiUrlString) : null;
  const patientDetails = localStorage.getItem("patientDetails");
  const apptDateTime=localStorage.getItem("apptdatetime");
  const { patient, resppartyID, selectedProfileIds } =
    JSON.parse(patientDetails);
  const [insuranceCarrierList, setInsuranceCarrierList] = useState([]);
  const [paginations, setPagination] = useState({
    page: pagination.page,
    pageCount: pagination.pageCount,
  });
  const [selectedInsurance, setSelectedInsurance] = useState();
  const [selectedCarrier, setSelectedCarrier] = useState();
  const [otherOptions, setotherOptions] = useState(false);

  // How did you hear about us changes, setting unique state for each radio btns
  const [searchEngine_state, setSearchEngine] = useState(false);
  const [socialMedia_state, setSocialMedia] = useState(false);
  const [advertisement_state, setAdvertisement] = useState(false);
  const [internetMedia_state, setInternetMedia] = useState(false);
  const [recommendedByFriends_state, setRecommendedByFriends] = useState(false);
  const [practioner_state, setPractioner] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const hearAbtUsList = ["Search Engine", "Social Media", "Referred from a Primary Care Practitioner", "Recommended by friend or colleague", "Journal, Blog, and Podcast", "Advertisements", "Insurance Provider", "Other"];
  const searchEngine_list = ["Google", "Bing", "DuckGo", "Other"];
  const socialMedia_list = ["Facebook", "Instagram", "LinkedIn", "Twitter(X)", "Other"];
  const advertisments_list = ["Google Ads", "Facebook Ads", "Email", "Billboard", "Kiosk", "Other"];
  const internetMedia_list = ["Psychology Today", "Thriving with ADHD", "Other"]
  const carriers = {
    "AETNA": 6047,
    "NORTH CAROLINA BLUE SHIELD": 6059,
    "NORTH CAROLINA MEDICARE": 6099,
    "NORTH CAROLINA MEDICAID": 6114,
    "MERITAIN HEALTH": 6155,
    "WELLCARE": 6174,
    "HUMANA": 6285,
    "UNITED HEALTHCARE": 6404,
    "CHAMPVA": 6447,
    "VETERANS ADMINISTRATION": 6471,
    "ANTHEM BLUE CROSS": 6515,
    "TRICARE FOR LIFE": 6559,
    "AARP": 6600,
    "CIGNA PPO": 6687,
    "GEHA HIGH OPTION": 6705,
    "MEDCOST": 6707,
    "GOLDEN RULE INSURANCE COMPANY": 6710,
    "OXFORD HEALTH PLAN": 6711,
    "BLUE MEDICARE NORTH CAROLINA": 6737,
    "LYRA HEALTH": 41446,
    "HEADWAY": 41512,
    "UMR MIDWEST SECURITIES": 41513,
    "ALL SAVERS INSURANCE": 41516,
    "UHC STUDENT RESOURCE": 41539,
    "BENEFIT PLAN ADMINISTRATORS": 41541,
    "MUTUAL OF OMAHA": 41543,
    "SELFPAY": 41545,
    "AETNA SENIOR SUPPLEMENTAL INSURANCE": 41548,
    "AMBETTER HEALTH PLAN": 41550,
    "DUKE GRANT": 41552,
    "NORTH CAROLINA STATE UNIVERISTY": 41553,
    "CAROLINA BEHAVIORAL HEALTH ALLIANCE": 41606,
    "COMPSYCH": 41630,
    "SUREST": 41638,
    "SUBSIDY PATIENT": 41646,
    "ALLIED BENEFIT SYSTEM": 41664
  }
  const stateOptions = [
    { value: 'AL', label: 'AL' },
    { value: 'AK', label: 'AK' },
    { value: 'AZ', label: 'AZ' },
    { value: 'AR', label: 'AR' },
    { value: 'CA', label: 'CA' },
    { value: 'CO', label: 'CO' },
    { value: 'CT', label: 'CT' },
    { value: 'DE', label: 'DE' },
    { value: 'FL', label: 'FL' },
    { value: 'GA', label: 'GA' },
    { value: 'HI', label: 'HI' },
    { value: 'ID', label: 'ID' },
    { value: 'IL', label: 'IL' },
    { value: 'IN', label: 'IN' },
    { value: 'IA', label: 'IA' },
    { value: 'KS', label: 'KS' },
    { value: 'KY', label: 'KY' },
    { value: 'LA', label: 'LA' },
    { value: 'ME', label: 'ME' },
    { value: 'MD', label: 'MD' },
    { value: 'MA', label: 'MA' },
    { value: 'MI', label: 'MI' },
    { value: 'MN', label: 'MN' },
    { value: 'MS', label: 'MS' },
    { value: 'MO', label: 'MO' },
    { value: 'MT', label: 'MT' },
    { value: 'NE', label: 'NE' },
    { value: 'NV', label: 'NV' },
    { value: 'NH', label: 'NH' },
    { value: 'NJ', label: 'NJ' },
    { value: 'NM', label: 'NM' },
    { value: 'NY', label: 'NY' },
    { value: 'NC', label: 'NC' },
    { value: 'ND', label: 'ND' },
    { value: 'OH', label: 'OH' },
    { value: 'OK', label: 'OK' },
    { value: 'OR', label: 'OR' },
    { value: 'PA', label: 'PA' },
    { value: 'RI', label: 'RI' },
    { value: 'SC', label: 'SC' },
    { value: 'SD', label: 'SD' },
    { value: 'TN', label: 'TN' },
    { value: 'TX', label: 'TX' },
    { value: 'UT', label: 'UT' },
    { value: 'VT', label: 'VT' },
    { value: 'VA', label: 'VA' },
    { value: 'WA', label: 'WA' },
    { value: 'WV', label: 'WV' },
    { value: 'WI', label: 'WI' },
    { value: 'WY', label: 'WY' }
  ];
  const getInsuranceCarrierList = async () => {
    try {
      const insuranceCarrierData = await ListOfInsuranceCarriers(
        {
          page: paginations?.page,
          apiUrl: `${apiUrl.XMLRPC}/xmlrpc/processrequest.aspx`,
        },
        dispatch
      );

      const newCarriers = insuranceCarrierData || [];
      setPagination(() => ({
        pageCount: 2,
      }));
      setInsuranceCarrierList((prevList) => [...prevList, ...newCarriers]);
    } catch (error) {
      console.error("Error fetching insurance carrier data:", error);
    } finally {
    }
  };

  useEffect(() => {
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", function (event) {
      window.history.pushState(null, document.title, window.location.href);
    });
  }, []);

  useEffect(() => {
    getInsuranceCarrierList();
  }, []);

  const handleInputChange = (field, value) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const handleRadioChange = async (value) => {
    setSelectedOption(value);
  };

  const handleKeyPress = (e, fieldType) => {
    const isNumber = e.key >= "0" && e.key <= "9";
    const isLetter =
      (e.key >= "A" && e.key <= "Z") || (e.key >= "a" && e.key <= "z");
    const isSpace = e.key === " ";
    const isComma = e.key === ",";
    const isHyphen = e.key === "-";
    const isApostrophe = e.key === "'";
    const isDigit = /\d/.test(e.key);
    const isBackspace = e.key === "Backspace";
    const inputValue = e.target.value;

    if (fieldType === "text") {
      // Allow only letters
      if (!isLetter) {
        e.preventDefault();
      }
    } else if (fieldType === "number") {
      // Allow only letters
      if (!isNumber) {
        e.preventDefault();
      }
    } else if (fieldType === "address") {
      // Allow only letters, numbers, spaces, and commas
      if (!(isLetter || isNumber || isSpace || isComma)) {
        e.preventDefault();
      }
    } else if (fieldType === "city") {
      // Allow only letters, spaces, hyphens, and apostrophes
      if (!(isLetter || isSpace || isHyphen || isApostrophe)) {
        e.preventDefault();
      }
    }  else if (fieldType === "insurance") {
      // Allow only letters and numbers
      if (!(isLetter || isNumber)) {
        e.preventDefault();
      }
    } else if (fieldType === "zip") {
      // Allow typing digits or hyphen, and backspace
      if (
        (isDigit && inputValue.length < 5) ||
        (isHyphen &&
          inputValue.indexOf("-") === -1 &&
          inputValue.length === 5) ||
        (isDigit && inputValue.indexOf("-") !== -1 && inputValue.length < 10) ||
        isBackspace
      ) {
        return;
      }

      e.preventDefault();
    }
  };

  const handleSubmit = async () => {
    const patientData = {
      "@id": patient.id.replace("pat", ""),
      "@o_insorder": "3",
      "@insorder": "2",
      "@primaryinsuranceplan": formData.primaryinsuranceplan,
      address: {
        ...(formData.zip && { "@zip": formData.zip }),
        ...(formData.city && { "@city": formData.city }),
        ...(formData.address && { "@address2": formData.address }),
        ...(formData.stateAddress && { "@state": formData.stateAddress }),
      },
      apiUrl: `${apiUrl.XMLRPC}/xmlrpc/processrequest.aspx`,
    };

    const response = await updateOnlineSchedulerPatient(patientData, dispatch);
    if (response) {
      const insuranceData = {
        "@id": patient.id.replace("pat", ""),
        "@changed": "1",
        insplanlist: {
          insplan: {
            insnote: "",
            "@id": "",
            "@begindate": new Date(),
            "@enddate": "",
            "@carrier": selectedInsurance,
            //"@carrier": selectedCarrier,
            "@subscriber": resppartyID,
            "@subscribernum": formData.subscribernum,
            "@hipaarelationship": patient.hipaarelationship,
            "@relationship": patient.relationship,
            "@grpname": "",
            "@grpnum": formData.grpnum,
            "@copay": "0.00",
            "@copaytype": "$",
            "@coverage": "1",   //1 = Primary, 2 = Secondary, 3 = Tertiary, 4 = Workers, 5 = Auto, 6 = DME
            "@payerid": "",
            "@mspcode": "",
            "@eligibilityid": "",
            "@eligibilitystatusid": "",
            "@eligibilitychangedat": "",
            "@eligibilitycreatedat": "",
            "@eligibilityresponsedate": "",
            "@deductible": "0.00",
            "@deductiblemet": "0.00",
            "@yearendmonth": "1",
            "@lifetime": "0.00",
          },
        },
        apiUrl: `${apiUrl.XMLRPC}/xmlrpc/processrequest.aspx`,
      };
      const insure = await AddPatientInsuranceDetails(insuranceData, dispatch);
      // FinancialClass_UID    Description
      // 8    MEDICARE
      // 10    MEDICAID
      // 12    SELF PAY
      // 20    COMMERCIAL
      // 275    LYRA HEALTH
      // 279    HEADWAY
      // 285    DUKE GRANT
      // 287    UNIVERSITY GRANT
      let finclass = 0;
      if(insure.success === 1)
      {
        switch(selectedInsurance) {
          case 6099: 
            finclass = "8";
            break;
          case 6114:
            finclass = "10";
            break;
          case 41446:
            finclass = "275";
            break;
          case 41512:
            finclass = "279";
            break;
          case 41545:
            finclass = "12";
            break;
          default: 
          finclass = "20";
        }
        const finclassPayload = {
        "@id": patient.id.replace("pat", ""),
        "@o_finclass": "12", 
        "@finclass": finclass,
        apiUrl: `${apiUrl.XMLRPC}/xmlrpc/processrequest.aspx`,
      }
       const finclassUpdate = await updateOnlineSchedulerPatient(finclassPayload, dispatch);
      }
      // Changes made by Monica Hegde & Daniyaer Saifuding on 07/15/24
      //Adding Hear about us to custom tab
      const customDatapayload = {
        patientId: patient.id.replace("pat", ""), //patient ID
        templateId: "508", //always 508 in production 
        templatefieldid: "4993", //heard of us through... text
        value: formData.hearAbtUs,
        apiUrl: `${apiUrl.XMLRPC}/xmlrpc/processrequest.aspx`,
      }
      const hearAbt = await createIAmNotPatient(customDatapayload, dispatch);

      const analyticalData = new FormData();
      analyticalData.append('Heard_about_us', formData.hearAbtUs);
      switch(formData.hearAbtUs){
        case "Other":
          analyticalData.append('Option', formData.otherOption);
          break;
        case "Recommended by friend or colleague":
          analyticalData.append('Option', formData.optionForRecFamily);
          break;
        case "Referred from a Primary Care Practitioner":
          analyticalData.append('Option', formData.optionForPractioner);
          break;          
        default:
          analyticalData.append('Option', selectedOption);
      }
      analyticalData.append('Date', moment().format("MM/DD/YYYY"));
      fetch(
        "https://script.google.com/macros/s/AKfycbxigcomIDT8xJqrgXjlGYxnMY6flWB-5qTk_B3FCTuBE_hOtmngVaCEOR_2Y0gpWLW_IA/exec",
        {
          method: "POST",
          body: analyticalData
        }
      )
    }
    if (response?.code === 200) {
      toast.success("Appointment scheduled Successfully");
      //navigate("/scheduler");
      window.location.href = "https://animosanopsychiatry.com/";
      localStorage.clear("patientDetails");
      localStorage.clear("profileIds");
    }
  };

  const handleBackButton = () => {
    navigate("/view-scheduler");
  };

  return (
    <div className="main-wrapper">
      <Container fluid>
        <div className="patient-container">
          <i className="fa-solid fa-circle-check icon-check"></i>
          <h5 className="heading-success">Your Visit Has Been Scheduled on {apptDateTime.substring(5,7)}/{apptDateTime.substring(8,10)}/{apptDateTime.substring(0,4)} at {Number(apptDateTime.substring(11,13)) > 12 ? Number(apptDateTime.substring(11,13))-12 : Number(apptDateTime.substring(11,13))}:{apptDateTime.substring(14,16)} {Number(apptDateTime.substring(11,13)) > 12 ? "PM" : "AM"} EST</h5>
          <p>Please share your address and insurance information below, alternatively you can add that information in the patient portal.</p>
          <p className="p-head-red"><b><u>Please complete below items prior to your visit.</u></b></p>
          <div className="instructions">
          <p>1. Please create your patient portal account using the following link Immediately: <a href="https://pp-wfe-100.advancedmd.com/149583/account/register" target="_blank">Advanced MD | Patient Portal</a><br></br>
          2. Prior to your visit you will receive consent, policy, and intake forms in the patient portal,<b><u>please be sure to complete these prior to your visit.</u></b> <br></br>
          3. If you are using insurance for your visit please be sure to upload an image of the front and back of your card in the patient portal. <b><u>Once uploaded, please be sure to send a portal message to the Q&A message group to advise the Administrative team so we can promptly add your insurance information to your account for billing.</u></b><br></br>
          4. To ensure the best possible care, we kindly request that you download your previous medical records from your current or previous patient portal and share them with us if they are available to you. These records help us understand your medical history and treatment plans, allowing us to continue your care seamlessly. You can share them in our patient portal or by email at <a href="mailto:group@animosanopsychiatry.com">group@animosanopsychiatry.com</a>.<br></br>
          5. At the time of your appointment you MUST have a valid ID to verify your identity.
          </p>
          </div>
          <p className="p-head-red"><b><u>On the day of your visit</u></b></p>
          <p>Please be sure to arrive 15 minutes early, you can access your provider's virtual room by clicking this <a href="https://aspsychiatry.doxy.me/" target="_blank">Telehealth Visit Link</a> or by going to <a href="https://aspsychiatry.doxy.me/" target="_blank">https://aspsychiatry.doxy.me/</a>.<br></br><br></br>
          If you find it necessary to cancel or reschedule, kindly note that our policy mandates a 48 hours' advance notice to avoid incurring a No-Show fee.<br></br>
          <b>Please don't hesitate to contact us for any assistance or inquiries. You can reach us at 919-391-7202, or simply drop us an email at <a href="mailto: info@animosanopsychiatry.com">info@animosanopsychiatry.com</a>. We're here to help!</b></p>
        </div>
        <div className="patient-container-card">
          <Card className="patient-card">
            <div className="d-flex">
              <i className="fa-solid fa-user"></i>
              <span className="ml-3">Patient Home Address</span>
            </div>
          </Card>
          <Row className="mt-3">
            <div>
              <input
                type="text"
                placeholder="Street Adress"
                value={formData.address}
                name="address"
                className="sch-input form-control"
                onKeyPress={(e) => handleKeyPress(e, "address")}
                onChange={(e) => handleInputChange("address", e.target.value)}
              />
            </div>
          </Row>
          <Row className="mt-3">
            <Col>
              <div>
                <input
                  type="text"
                  placeholder="City"
                  value={formData.city}
                  name="city"
                  className="sch-input form-control"
                  onKeyPress={(e) => handleKeyPress(e, "city")}
                  onChange={(e) => handleInputChange("city", e.target.value)}
                />
              </div>
            </Col>
            <Col>
              <div>
                <input
                  type="text"
                  placeholder="Zip"
                  value={formData.zip}
                  name="zip"
                  className="sch-input form-control"
                  onKeyPress={(e) => handleKeyPress(e, "zip")}
                  onChange={(e) => handleInputChange("zip", e.target.value)}
                />
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
              {/* <label className="sch-label mt-3">State</label> */}
                  <div>
                  <select
                    className="sch-input form-select form-select-sm"
                    name="state"
                    value={formData.stateAddress}
                    onChange={(e) => handleInputChange("stateAddress", e.target.value)}
                    >
                    <option className="state-options" value="">State</option>
                    {stateOptions.map((state) => (
                      <option key={state.value} value={state.value}>
                        {state.label}
                      </option>
                    ))}
                    </select>
                  </div>
          </Row>
          <Card className="patient-card">
            <div className="d-flex">
              <i className="fa-solid fa-user"></i>
              <span>Patient's Insurance Details</span>
            </div>
          </Card>
          <Row className="mt-3">
            <div>
              {/* <Dropdown
                options={insuranceCarrierList
                  ?.filter((item) => item.useinintake === "1")
                  .map((item) => ({
                    ...item,
                  }))}
                optionLabel="description"
                optionValue="uid"
                showClear
                filter
                className="sch-input"
                placeholder="Insurance Carrier"
                value={selectedInsurance}
                onChange={(e) => {
                  setSelectedInsurance(e.value)
                  const selectedOption = insuranceCarrierList.find(option => option.uid === e.value);
                  setSelectedCarrier(carriers[selectedOption.description])
                }}
              /> */}
              <Dropdown
              options={Object.entries(carriers).map(([label, value]) => ({ label, value }))}
              optionLabel="label"
              optionValue="value"
              showClear
              filter
              className="sch-input"
              placeholder="Insurance Carrier"
              value={selectedInsurance}
              onChange={(e) => {
                setSelectedInsurance(e.value);
                //setSelectedCarrier(e.label);
              }}
              />
            </div>
          </Row>
          <Row className="mt-3">
            <div>
              <input
                type="text"
                placeholder="Subscriber Number"
                value={formData.subscribernum}
                name="subscribernum"
                className="sch-input form-control"
                onKeyPress={(e) => handleKeyPress(e, "insurance")}
                onChange={(e) =>
                  handleInputChange("subscribernum", e.target.value)
                }
              />
            </div>
          </Row>
          <Row className="mt-3">
            <div>
              <input
                type="text"
                placeholder="Group Number"
                value={formData.grpnum}
                name="grpnum"
                className="sch-input form-control"
                onKeyPress={(e) => handleKeyPress(e, "insurance")}
                onChange={(e) => handleInputChange("grpnum", e.target.value)}
              />
            </div>
          </Row>
          <Card className="patient-card">
            <div className="d-flex">
              <i className="fa-solid fa-user"></i>
              <span>How did you hear about us?<i style={{fontSize:"12px"}}>(Optional)</i></span>
            </div>
          </Card>
        <Row className="mt-3">
          <div>
            <select
              className="sch-input form-select form-select-sm"
              name="company"
              value={formData.hearAbtUs}
              onChange={(e) => {
                // if(e.target.value == "Other")
                //   {
                //     setotherOptions(true)
                //     handleInputChange("hearAbtUs", e.target.value)
                //   } else {
                //     setotherOptions(false)
                //     handleInputChange("hearAbtUs", e.target.value)}

                    // when target.value == options with multiple choices, radio btns spwan
                    const value = e.target.value;
                    handleInputChange("hearAbtUs", value);
    
                    setSearchEngine(false);
                    setSocialMedia(false);
                    setAdvertisement(false);
                    setInternetMedia(false);
                    setPractioner(false);
                    setRecommendedByFriends(false);
                    setotherOptions(false);
    
                    switch (value) {
                      case "Search Engine":
                        setSearchEngine(true);
                        break;
                      case "Advertisements":
                        setAdvertisement(true);
                        break;
                      case "Social Media":
                        setSocialMedia(true);
                        break;
                      case "Journal, Blog, and Podcast":
                        setInternetMedia(true);
                        break;
                      case "Recommended by friend or colleague":
                        setRecommendedByFriends(true);
                        break;
                      case "Referred from a Primary Care Practitioner":
                        setPractioner(true);
                        break;              
                      case "Other":
                        setotherOptions(true);
                        break;
                      default:
                        break;
                    }
                  }
                }
              >
                <option value="">Please select</option>
                {hearAbtUsList.map((opt, index) => (
                <option key={index} value={opt}>
                {opt}
                </option>
              ))}
              </select>
              { searchEngine_state && (
              <div style={{ marginTop: "15px" }}>
                {searchEngine_list.map((opt, index) => (
                  <div key={index}>
                    <label className="sch-label">
                      <input
                        type="radio"
                        name="searchEngine"
                        value={opt}
                        checked={selectedOption === opt}
                        onChange={() => 
                          handleRadioChange(opt)
                        }
                      />
                      <span className="radio-label-text" style={{fontSize:"14px"}}>{opt}</span>
                    </label>
                  </div>
                ))}
              </div>
            )
              }
              { advertisement_state && (
                <div style={{ marginTop: "15px" }}>
                {advertisments_list.map((opt, index) => (
                  <div key={index}>
                    <label className="sch-label">
                      <input
                        type="radio"
                        name="advertisement"
                        value={opt}
                        checked={selectedOption === opt}
                        onChange={() =>
                          handleRadioChange(opt)
                        }
                      />
                      <span className="radio-label-text" style={{fontSize:"14px"}}>{opt}</span>
                    </label>
                  </div>
                ))}
              </div>
              )
              }
              { socialMedia_state && (
                <div style={{ marginTop: "15px" }}>
                {socialMedia_list.map((opt, index) => (
                  <div key={index}>
                    <label className="sch-label">
                      <input
                        type="radio"
                        name="socialMedia"
                        value={opt}
                        checked={selectedOption === opt}
                        onChange={() => 
                          handleRadioChange(opt)
                        }
                      />
                      <span className="radio-label-text" style={{fontSize:"14px"}}>{opt}</span>
                    </label>
                  </div>
                ))}
              </div>
              )
              }
              { internetMedia_state && (
                <div style={{ marginTop: "15px" }}>
                {internetMedia_list.map((opt, index) => (
                  <div key={index}>
                    <label className="sch-label">
                      <input
                        type="radio"
                        name="internetMedia"
                        value={opt}
                        checked={selectedOption === opt}
                        onChange={() =>
                          handleRadioChange(opt)
                        }
                      />
                      <span className="radio-label-text" style={{fontSize:"14px"}}>{opt}</span>
                    </label>
                  </div>
                ))}
              </div>
              )
              }
              { recommendedByFriends_state && (
                <div style= {{ marginTop : "15px"}}>
                  <input
                    type="text"
                    value={formData.optionForRecFamily}                
                    placeholder="Recommended By"
                    className="sch-input form-control"
                    onChange={(e) => {
                      const newValue = e.target.value;
                      handleInputChange("optionForRecFamily", newValue);
                    }                    
                  }
                  />
                </div>
              )}
              { practioner_state && (
                <div style= {{ marginTop : "15px"}}>
                  <input
                    type="text"
                    value={formData.optionForPractioner}                
                    placeholder="Recommended By"
                    className="sch-input form-control"
                    onChange={(e) => {
                      const newValue = e.target.value;
                      handleInputChange("optionForPractioner", newValue);
                    }                    
                  }
                  />
                </div>
              )}
              { otherOptions && (
                <div style= {{ marginTop : "15px"}}>
                    <input
                    type="text"
                    value={formData.otherOption}                
                    //onKeyPress={(e) => handleKeyPress(e, "lastname")}
                    placeholder="Other"
                    className="sch-input form-control"
                    onChange={(e) => {
                      const newValue = e.target.value;
                      handleInputChange("otherOption", newValue);
                      // setSelectedOption(newValue)
                    //   setFormData(prevState => ({
                    //     ...prevState,
                    //     hearAbtUs: formData.otherOption
                    // }));
                    }                    
                  }
                  />
                </div>
              )
              }
            </div>
          </Row>
          <div className="mt-5 d-flex justify-content-center align-items-center">
            {/* <button
              className="form-submitbtn1"
              onClick={() => {
                handleBackButton();
              }}
            >
              Back
            </button> */}
            <button
              className="form-submitbtn3"
              onClick={() => {
                handleSubmit();
              }}
            >
              Submit Form
            </button>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default PatientDetails;
