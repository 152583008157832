import axios from "axios";
import { toast } from "react-toastify";
import { URL as HTTP_URL } from "../config/http";

export const BUILD_ENV = parseInt(process.env.REACT_APP_BUILD_ENV);
const schedulerToken = localStorage.getItem("schedulerToken");

onlineSchedulerRequest
const baseUrls = {
  0: HTTP_URL.development.http,
  1: HTTP_URL.staging.http,
  2: HTTP_URL.prod.http,
};
const URL = baseUrls[BUILD_ENV];
export const publicRequest = axios.create({
  baseURL: URL,
});

export const onlineSchedulerRequest = axios.create({
  baseURL: URL,
  headers: { Authorization: `${schedulerToken}` },
});

onlineSchedulerRequest.interceptors.request.use((config) => {
  const token = localStorage.getItem("schedulerToken");
  if (token) {
    config.headers["Authorization"] = token;
  }
  config.headers.Accept = "application/json";
  return config;
});