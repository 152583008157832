import { createSlice } from "@reduxjs/toolkit";

const imageThumbSlice = createSlice({

  name: "imageThumbSlice",
  initialState: {
    path: {},
  },
  reducers: {
    setImageThumb: (state, action) => {
        console.log(typeof action.payload);
      state.path = action.payload;
    },
    removeImageThumb: (state, action) => {
      state.path = null;
    },
  },
});

export const { setImageThumb, removeImageThumb } = imageThumbSlice.actions;
export default imageThumbSlice.reducer;
