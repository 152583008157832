import { publicRequest, onlineSchedulerRequest } from "./http.service";
import { fullPageStart, fullPageStop } from "../slice/loader.slice";
import { toast } from "react-toastify";
import { schedulerLoginSuccess } from "../slice/providerSlice";
import queryString from "query-string";

export const onlineSchedulerLogin = async (dispatch, data) => {
  //   dispatch(fullPageStart());
  try {
    const response = await publicRequest.post("/provider/login", data);
    if (response.error === "We are Working on it please try after some time") {
      toast.error("Try After Some Time");
    }
    if (response.data.status == "SUCCESS") {
      const token = response.data?.data?.userContext;
      await dispatch(schedulerLoginSuccess(response.data.data));
      localStorage.setItem("schedulerToken", token);
      const urls = {
        XMLRPC: response.data.data.provider.usercontext.webserver,
        RESTPM: response.data.data.provider.usercontext.webserver.replace(
          "/processrequest/",
          "/api/"
        ),
        RESTAPIPM: response.data.data.provider.usercontext.pmapiredirecturl,
      };
      localStorage.setItem("schedulerUrl", JSON.stringify(urls));
      // toast.success(response.data.message);
      return response.data;
    }
    if (response.data.status !== "SUCCESS") {
      toast.error(response.data.message);
    }
  } catch (err) {
    console.error(err, "Service failed");
  } finally {
    // dispatch(fullPageStop());
  }
};

export const getToken = async (dispatch, data) => {
  //   dispatch(fullPageStart());
  try {
    const response = await publicRequest.post("/advancemd/onlinescheduler/get-token", data);
    if (response.data.status == "SUCCESS") {
      const token = response.data?.data?.token;
      await dispatch(schedulerLoginSuccess(response.data.data));
      localStorage.setItem("schedulerToken", token);
      const urls = {
        XMLRPC: response.data.data.userContext.usercontext.webserver,
        RESTPM: response.data.data.userContext.usercontext.webserver.replace(
          "/processrequest/",
          "/api/"
        ),
        RESTAPIPM: response.data.data.userContext.usercontext.pmapiredirecturl,
      };
      localStorage.setItem("schedulerUrl", JSON.stringify(urls));
      // toast.success(response.data.message);
      return response.data;
    }
    if (response.data.status !== "SUCCESS") {
      toast.error(response.data.message);
    }
  } catch (err) {
    console.error(err, "Service failed");
  } finally {
    // dispatch(fullPageStop());
  }
};

export const lookupPatient = async(data, dispatch) => {
  dispatch(fullPageStart());
  try {
    const response = await onlineSchedulerRequest.post(
      "/advancemd/onlinescheduler/lookup-patient",
      data
    );
    if (response.data.status == "SUCCESS") {
      //   toast.success(response.data.message);
      return response.data;
    }
    if (response.data.status !== "SUCCESS") {
      toast.error(response.data.message);
    }
  } catch (err) {
    console.error(err, "Service failed");
  } finally {
    dispatch(fullPageStop());
  }
};

export const createOnlineSchedulerPatient = async (data, dispatch) => {
  dispatch(fullPageStart());
  try {
    const response = await onlineSchedulerRequest.post(
      "/advancemd/onlinescheduler/create-patient",
      data
    );
    if (response.data.status == "SUCCESS") {
      //   toast.success(response.data.message);
      return response.data;
    }
    if (response.data.status !== "SUCCESS") {
      toast.error(response.data.message);
    }
  } catch (err) {
    console.error(err, "Service failed");
  } finally {
    dispatch(fullPageStop());
  }
};

export const sendConfirmationEMail = async(data, dispatch) => {
  try {
    const response = await onlineSchedulerRequest.post(
      "/appointment/send-email",
      data
    );
    if (response.data.status == "SUCCESS") {
      //   toast.success(response.data.message);
      return response.data;
    }
    if (response.data.status !== "SUCCESS") {
      toast.error("Error sending email");
    } 
  } catch (err) {
    console.error(err, "Couldn't send the confirmation");
  } finally {
     dispatch(fullPageStop());
  }
}
export const intakeOnlineRequest = async (data, dispatch) => {
  // dispatch(fullPageStart());
  try {
    const response = await onlineSchedulerRequest.post(
      "/appointment/intake",
      data
    );
    if (response.data.status == "SUCCESS") {
      //   toast.success(response.data.message);
      return response.data;
    }
    if (response.data.status !== "SUCCESS") {
      toast.error(response.data.message);
    }
  } catch (err) {
    console.error(err, "Service failed");
  } finally {
    // dispatch(fullPageStop());
  }
};


export const createIAmNotPatient = async (data, dispatch) => {
  // dispatch(fullPageStart());
  try {
    const response = await onlineSchedulerRequest.post(
      "/advancemd/onlinescheduler/create-not-patient",
      data
    );
    if (response.data.status == "SUCCESS") {
      //   toast.success(response.data.message);
      return response.data;
    }
    if (response.data.status !== "SUCCESS") {
      toast.error(response.data.message);
    }
  } catch (err) {
    console.error(err, "Service failed");
  } finally {
    // dispatch(fullPageStop());
  }
};
export const updateOnlineSchedulerPatient = async (data, dispatch) => {
  dispatch(fullPageStart());
  try {
    const response = await onlineSchedulerRequest.post(
      "/advancemd/onlinescheduler/update-patient",
      data
    );
    if (response.data.status == "SUCCESS") {
      //   toast.success(response.data.message);
      return response.data;
    }
    if (response.data.status !== "SUCCESS") {
      toast.error(response.data.message);
    }
  } catch (err) {
    console.error(err, "Service failed");
  } finally {
    dispatch(fullPageStop());
  }
};

export const getEpisodes = async (data, dispatch) => {
  dispatch(fullPageStart());
  try {
    const response = await onlineSchedulerRequest.post(
      "/appointment/episodes",
      data
    );
    if (response.data.status == "SUCCESS") {
      //   toast.success(response.data.message);
      return response.data;
    }
    if (response.data.status !== "SUCCESS") {
      toast.error(response.data.message);
    }
  } catch (err) {
    console.error(err, "Service failed");
  } finally {
    dispatch(fullPageStop());
  }
};

// Edit appointmrnt by id
export const editAppointment = async (data, dispatch) => {
  dispatch(fullPageStart());
  try {
    const response = await onlineSchedulerRequest.post(
      "/appointment/edit",
      data
    );
    if (response.data.status == "SUCCESS") {
      //   toast.success(response.data.message);
      return response.data;
    }
    if (response.data.status !== "SUCCESS") {
       toast.error(response.data.message);
    }
  } catch (err) {
    console.error(err, "Service failed");
  } finally {
    dispatch(fullPageStop());
  }
};

export const schedulerProviderList = async (dispatch, apiUrl) => {
  dispatch(fullPageStart());
  try {
    const response = await onlineSchedulerRequest.post(`/provider/list`, {
      apiUrl,
    });
    if (response.data.status == "SUCCESS") {
      return response.data.data.providerlist.provider;
    }
    if (response.data.status !== "SUCCESS") {
    }
  } catch (err) {
    console.error(err, "Service failed");
  } finally {
    dispatch(fullPageStop());
  }
};

export const getproviderTypesList = async (apiUrl, dispatch) => {
  dispatch(fullPageStart());
  try {
    const response = await onlineSchedulerRequest.post(
      `/advancemd/onlinescheduler/list-providertypes`,
      { apiUrl }
    );
    if (response.data.status == "SUCCESS") {
      return response.data.data;
    }
    if (response.data.status !== "SUCCESS") {
    }
  } catch (err) {
    console.error(err, "Service failed");
  } finally {
    dispatch(fullPageStop());
  }
};

export const listOfColumns = async (dispatch, apiUrl) => {
  try {
    const response = await onlineSchedulerRequest.post(
      `/provider/column-list`,
      {
        apiUrl,
      }
    );
    if (response.data.status == "SUCCESS") {
      return response.data.data;
    }
    if (response.data.status !== "SUCCESS") {
    }
  } catch (err) {
    console.error(err, "Service failed");
  } finally {
  }
};

export const listOfBlockHolds = async (data, apiUrl, dispatch) => {
  dispatch(fullPageStart());
  try {
    const response = await onlineSchedulerRequest.post(
      `/appointment/blockholds-appt-list?${queryString.stringify(data)}`,
      { apiUrl }
    );
    if (response.data.status == "SUCCESS") {
      return response.data.data;
    }
    if (response.data.status !== "SUCCESS") {
      // toast.error(response.data.message);
    }
  } catch (err) {
    console.error(err, "Service failed");
  } finally {
  }
};

export const listOfAppointments = async (data, apiUrl, dispatch) => {
  try {
    const response = await onlineSchedulerRequest.post(
      `/appointment/getall?${queryString.stringify(data)}`,
      { apiUrl }
    );
    if (response.data.status == "SUCCESS") {
      return response.data.data;
    }
    if (response.data.status !== "SUCCESS") {
      // toast.error(response.data.message);
    }
  } catch (err) {
    console.error(err, "Service failed");
  } finally {
    // dispatch(fullPageStop());
  }
};

export const schedulerAppointmentTypesList = async (dispatch, apiUrl) => {
  try {
    const response = await onlineSchedulerRequest.post(
      "/appointment/apitypes",
      {
        apiUrl,
      }
    );
    if (response.data.status === "SUCCESS") {
      return response.data.data;
    }
    if (response.data.status !== "SUCCESS") {
      toast.error(response.data.message);
    }
  } catch (err) {
    console.error(err, "Service failed");
  } finally {
  }
};

export const schedulerCreateAppointment = async (data, dispatch) => {
  dispatch(fullPageStart());
  try {
    const response = await onlineSchedulerRequest.post(
      "/appointment/create",
      data
    );
    if (response.data.status == "SUCCESS") {
      return response.data;
    }
    if (response.data.status !== "SUCCESS") {
      toast.error(response.data.message);
    }
  } catch (err) {
    console.error(err, "Service failed");
  } finally {
    dispatch(fullPageStop());
  }
};

/**Insurance Api's */
export const ListOfInsuranceCarriers = async (payload, dispatch) => {
  try {
    const response = await onlineSchedulerRequest.post(
      "/advancemd/onlinescheduler/list-carriers",
      payload
    );
    if (response.data.status === "SUCCESS") {
      return response.data.data;
    }
    if (response.data.status !== "SUCCESS") {
      toast.error(response.data.message);
    }
  } catch (err) {
    console.error(err, "Service failed");
  } finally {
  }
};

export const AddPatientInsuranceDetails = async (data, dispatch) => {
  try {
    const response = await onlineSchedulerRequest.post(
      "/advancemd/onlinescheduler/update-insurance",
      data
    );
    if (response.data.status === "SUCCESS") {
      return response.data.data;
    }
    if (response.data.status !== "SUCCESS") {
      toast.error(response.data.message);
    }
  } catch (err) {
    console.error(err, "Service failed");
  } finally {
  }
};

/**File Template Api's */
export const ListOfFileTemplates = async (payload, dispatch) => {
  try {
    const response = await onlineSchedulerRequest.post(
      "/advancemd/onlinescheduler/list-templates",
      payload
    );
    if (response.data.status === "SUCCESS") {
      return response.data.data;
    }
    if (response.data.status !== "SUCCESS") {
      toast.error(response.data.message);
    }
  } catch (err) {
    console.error(err, "Service failed");
  } finally {
  }
};
