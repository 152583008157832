import React, { useEffect, useState } from "react";
import { Col, Row, Modal, Button } from "react-bootstrap";
import "./index.scss";
import { SelectButton } from "primereact/selectbutton";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Select from 'react-select';
import { FileUpload } from 'primereact/fileupload';
import { Toast } from 'primereact/toast';
import {
  ListOfFileTemplates,
  createIAmNotPatient,
  createOnlineSchedulerPatient,
  getToken,
  getproviderTypesList,
  schedulerProviderList,
  intakeOnlineRequest,
  lookupPatient
} from "../../services/onlinescheduler.service";
import vector from "../../assets/images/Logo-3.png";
import moment from "moment";
import { toast } from "react-toastify";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";
import { grey } from "@mui/material/colors";

const steps = ["", "", ""];

const stateOptions = [
  { value: 'AL', label: 'AL' },
  { value: 'AK', label: 'AK' },
  { value: 'AZ', label: 'AZ' },
  { value: 'AR', label: 'AR' },
  { value: 'CA', label: 'CA' },
  { value: 'CO', label: 'CO' },
  { value: 'CT', label: 'CT' },
  { value: 'DE', label: 'DE' },
  { value: 'FL', label: 'FL' },
  { value: 'GA', label: 'GA' },
  { value: 'HI', label: 'HI' },
  { value: 'ID', label: 'ID' },
  { value: 'IL', label: 'IL' },
  { value: 'IN', label: 'IN' },
  { value: 'IA', label: 'IA' },
  { value: 'KS', label: 'KS' },
  { value: 'KY', label: 'KY' },
  { value: 'LA', label: 'LA' },
  { value: 'ME', label: 'ME' },
  { value: 'MD', label: 'MD' },
  { value: 'MA', label: 'MA' },
  { value: 'MI', label: 'MI' },
  { value: 'MN', label: 'MN' },
  { value: 'MS', label: 'MS' },
  { value: 'MO', label: 'MO' },
  { value: 'MT', label: 'MT' },
  { value: 'NE', label: 'NE' },
  { value: 'NV', label: 'NV' },
  { value: 'NH', label: 'NH' },
  { value: 'NJ', label: 'NJ' },
  { value: 'NM', label: 'NM' },
  { value: 'NY', label: 'NY' },
  { value: 'NC', label: 'NC' },
  { value: 'ND', label: 'ND' },
  { value: 'OH', label: 'OH' },
  { value: 'OK', label: 'OK' },
  { value: 'OR', label: 'OR' },
  { value: 'PA', label: 'PA' },
  { value: 'RI', label: 'RI' },
  { value: 'SC', label: 'SC' },
  { value: 'SD', label: 'SD' },
  { value: 'TN', label: 'TN' },
  { value: 'TX', label: 'TX' },
  { value: 'UT', label: 'UT' },
  { value: 'VT', label: 'VT' },
  { value: 'VA', label: 'VA' },
  { value: 'WA', label: 'WA' },
  { value: 'WV', label: 'WV' },
  { value: 'WI', label: 'WI' },
  { value: 'WY', label: 'WY' }
];

const companies = ["Concert Health", "Duke Health", "UNC Health" , "Haven at Blue Creek", "NOCD", "Other"]

const OnlineScheduler = () => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [showmodel, setShowModel] = useState(false);
  const options = [
    { name: "I am the patient", value: true },
    { name: "I am NOT the patient", value: false },
  ];
  const [isPatient, setIsPatient] = useState(true);
  const [templateId, setTemplateId] = useState();
  const [isNext, setIsNext] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [otherCompany, setotherCompany] = useState(false);
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    emailError: "",
    submitteremailError: "",
    minorAge: "",
    dob: null,
    birthsex: "",
    email: "",
    phone: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    ispatient: true,
    islegalguardian: "guardian",
    submitterFirstName: "",
    submitterLastName: "",
    submitterPhone: "",
    referringProviderName: "",
    isReferingProvider: false,
    otherOption: false,
    relation2Patient: "",
    reason: {},
    dea: "",
    patient_seen: "",
    clinicalNotes: "",
    Submitteremail: "",
    Submitterfax: "",
    affiliatedCompany: "",
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const apiUrlString = localStorage.getItem("schedulerUrl");
  const apiUrl = apiUrlString ? JSON.parse(apiUrlString) : null;
  const [mmpID, setmmpID] = useState(null);
  const [psyID, setpsyID] = useState(null);
  const [patient, setPatient] = useState();
  const [profileIds, setProfileIds] = useState(null);
  const [templateFiles, setTemplateFiles] = useState([]);
  const [formErrors, setFormErrors] = useState({
    relation2Patient: "",
    referringProviderName: "",
    submitterFirstName: "",
    submitterLastName: "",
    submitterPhone: "",
    submitterEmail: "",
    submitterfax: ""
  });
  const [dateError, setError] = React.useState(null);
  const [isTokenReady, setIsTokenReady] = useState(false);

  const errorMessage = React.useMemo(() => {
    switch (dateError) {
      case "disableFuture": {
        return "Please select a valid date";
      }

      default: {
        return "";
      }
    }
  }, [dateError]);

  const clear = () => {
    setFormData({
      firstname: "",
      lastname: "",
      emailError: "",
      minorAge: "",
      dob: "",
      birthsex: "",
      email: "",
      phone: "",
      address: "",
      city: "",
      state: "",
      zip: "",
      ispatient: true,
      islegalguardian: "guardian",
      submitterFirstName: "",
      submitterLastName: "",
      submitterPhone: "",
      referringProviderName: "",
      isReferingProvider: false,
      otherOption: false,
      relation2Patient: "",
      reason: {},
      dea: "",
      patient_seen: "",
    });
  };
  useEffect(() => {
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", function (event) {
      window.history.pushState(null, document.title, window.location.href);
    });
  }, []);

  const handleClose = () => {
    setShowModel(false);
    setSelectedOption(null);
    setActiveStep(0);
    setIsPatient(true);
  };
  const handleShow = () => setShowModel(true);

  const handleRadioChange = async (value) => {
    setSelectedOption(value);
  };

  const handleToken = async (value) => {
    try {
      const payload = {
        // username: process.env.REACT_APP_AMD_USERNAME,
        // password: process.env.REACT_APP_AMD_PASSWORD,
        // officecode: process.env.REACT_APP_AMD_OFFICEKEY,
        username: '',
        password: '',
        officecode: '',
      };
      const result = await getToken(dispatch, payload);
      setIsTokenReady(true);
    } catch (error) {
      console.error("Login error:", error);
      toast.error("An error occurred while logging in. Please try again."); // Use your toast library or function here
    }
  };
  // useEffect(() => {
  //   handleToken();
  //   getAllApis();
  // }, []);

  useEffect(() => {
    handleToken();
  }, []);

  useEffect(() => {
    if (isTokenReady) {
      getAllApis();
    }
  }, [isTokenReady]);

  // useEffect(() => {
  //   if (selectedOption === "new") {
  //     getAllApis();
  //     // getListOfFileTemplates();
  //   }
  // }, [selectedOption]);


  const getAllApis = async () => {
    try {
      const providertypes = await getproviderTypesList(apiUrl.RESTPM, dispatch);
      let selectedProvTypes = providertypes?.filter(
        (type) =>
          type.name === "Psychotherapist" ||
          type.name === "Medication Management Provider"
      );
      const mmpID = selectedProvTypes.find(
        (type) => type.name === "Medication Management Provider"
      );
      const psyID = selectedProvTypes.find(
        (type) => type.name === "Psychotherapist"
      );
      setmmpID(mmpID);
      setpsyID(psyID);
      const providerlist = await schedulerProviderList(dispatch, apiUrl.XMLRPC);
      let profileIDs = {};

      for (const provider of providerlist) {
        const {
          type: providerType,
          status: providerStatus,
          profilelist,
        } = provider;

        if (
          (providerType === mmpID.name ||
            providerType === psyID.name ||
            providerType === "SOCIAL WORKER") &&
          providerStatus === "ACTIVE"
        ) {
          const profiles = Array.isArray(profilelist.profile)
            ? profilelist.profile
            : [profilelist.profile];

          let defaultId = null;

          for (const profile of profiles) {
            const { id, isdefault } = profile;

            if (isdefault === "1") {
              defaultId = id;
            }
          }

          for (const profile of profiles) {
            const { state, description } = profile;

            const typeStateKey = `${providerType}-${state}`;
            profileIDs[typeStateKey] = profileIDs[typeStateKey] || [];

            if (defaultId) {
              const typeStateKey = `${
                providerType === "SOCIAL WORKER" ? psyID.name : providerType
              }-${state}`;

              profileIDs[typeStateKey].push({
                id: defaultId,
                name: description,
                title: provider.title
              });
            }
          }
        }
      }

      // Merge Social Worker profiles with Psychotherapist profiles
      const psychotherapistKey = `${psyID.name}-`;
      const socialWorkerKey = `${psyID.name}-`;
      if (profileIDs[socialWorkerKey]) {
          if (!profileIDs[psychotherapistKey]) {
              profileIDs[psychotherapistKey] = [];
          }
          profileIDs[psychotherapistKey] = [
              ...profileIDs[psychotherapistKey],
              ...profileIDs[socialWorkerKey]
          ];
          delete profileIDs[socialWorkerKey]; // Remove the separate key for Social Worker profiles
      }

      setProfileIds(profileIDs);

      localStorage.setItem("profileIds", JSON.stringify(profileIDs));
    } catch (error) {
      console.error("Error fetching appointments:", error);
    }
  };

  const calculateAge = (dob) => {
    const birthDate = new Date(dob);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    if (
      today.getMonth() < birthDate.getMonth() ||
      (today.getMonth() === birthDate.getMonth() &&
        today.getDate() < birthDate.getDate())
    ) {
      age -= 1;
    }
    return age;
  };

  const handleInputChange = (field, value) => {
    if (field === "email") {
      const isValidEmail =
        value.trim() === "" || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);

      setFormData({
        ...formData,
        [field]: value,
        emailError: isValidEmail ? "" : "Invalid email address",
      });
    } 
    else if (field === "Submitteremail") {
      const isValidEmail =
        value.trim() === "" || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);

      setFormData({
        ...formData,
        [field]: value,
        emailErrorsub: isValidEmail ? "" : "Invalid email address",
      });
    }
    else if (field === "dob") {
      const calculatedAge = calculateAge(value);
      const ageValidationMessage =
        calculatedAge <= 18
          ? "Patient must be 18 years of age or older to proceed."
          : "";

      setFormData({
        ...formData,
        [field]: value,
        minorAge: ageValidationMessage,
        islegalguardian: isPatient ? "" : formData.islegalguardian,
      });
    }
    else {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [field]: "",
      }));
      setFormData({
        ...formData,
        [field]: value,
      });
    }
  };

  const isFormValid = () => {
    const requiredFields = [
      "firstname",
      "lastname",
      "dob",
      "birthsex",
      "email",
      "phone",
    ];

    let hasErrors = false;
    if (!isPatient) {
      if (
        formData.islegalguardian === "guardian" &&
        !formData.relation2Patient
      ) {
        hasErrors = true;
      }
      if (formData.islegalguardian === "other" && !formData.relation2Patient) {
        hasErrors = true;
      }
      if (formData.islegalguardian === "") {
        hasErrors = true;
      }
      if (
        formData.islegalguardian === "refer" &&
        !formData.submitterFirstName &&
        !formData.submitterLastName
      ) {
        hasErrors = true;
      }

      if (!isPatient) {
        if (
          !formData.submitterFirstName ||
          !formData.submitterLastName ||
          !formData.submitterPhone
        ) {
          hasErrors = true;
        }
      }

      return (
        !hasErrors &&
        !dateError &&
        requiredFields.every((field) => {
          const value = formData[field];
          return value !== "" && value !== false && value !== null;
        })
      );
    } else {
      const hasNoErrors = !formData.emailError && !dateError;
      if (formData.minorAge !== "") {
        if (
          formData.islegalguardian === "guardian" &&
          !formData.relation2Patient
        ) {
          hasErrors = true;
        }
        if (formData.islegalguardian === "") {
          hasErrors = true;
        }
        if (
          formData.islegalguardian === "other" &&
          !formData.relation2Patient
        ) {
          hasErrors = true;
        }
        if (
          formData.islegalguardian === "refer" &&
          !formData.submitterFirstName
        ) {
          hasErrors = true;
        }
        if (
          formData.islegalguardian === "refer" &&
          !formData.submitterLastName
        ) {
          hasErrors = true;
        }
        return (
          requiredFields.every((field) => {
            const value = formData[field];
            return value !== "" && value !== false && value !== null;
          }) &&
          hasNoErrors &&
          !hasErrors
        );
      } else {
        return (
          requiredFields.every((field) => {
            const value = formData[field];
            return value !== "" && value !== null;
          }) && hasNoErrors
        );
      }
    }
  };

  const handleSubmit = async () => {
    const button = document.getElementById("Next-button");
    button.disabled = true;
    let relationshipValue = "";
    let hipaaRelationshipValue = "";
    if (!formData.ispatient) {
      if (formData.islegalguardian === "guardian") {
        relationshipValue = "4";
        hipaaRelationshipValue = formData.relation2Patient;
      } else if (formData.islegalguardian === "refer") {
        relationshipValue = "4";
        hipaaRelationshipValue = "G8";
      } else if (formData.islegalguardian === "other") {
        relationshipValue = formData.relation2Patient === "2";
        hipaaRelationshipValue = formData.relation2Patient;
      }
    } else {
      // Handle the case for "I am the patient"
      relationshipValue = "1";
      hipaaRelationshipValue = "18";
    }
    // Added formatting to avoid momentjs formatting which was returning current date and used in line 463
    // Changes made by Monica Hegde on 3/14/2024 
    var formatteddob=  (formData.dob.$d.getMonth() + 1) + '-' + formData.dob.$d.getDate() + '-' +  formData.dob.$d.getFullYear()
    // Value F,M and U based on the gender selected
    // Changes made by Monica Hegde on 3/18/2024
    const birthSexValue = formData.birthsex === "female" ? "F" : formData.birthsex === "male" ? "M" : "U";
    const payload = {
      patient: {
        "@name": formData.firstname + " " + formData.lastname,
        "@dob": formatteddob,
        "@relationship": relationshipValue,
        "@hipaarelationship": hipaaRelationshipValue,
        "@finclass": "12",
        "@profile": "29",
        "@chart": "AUTO",
        "@sex": birthSexValue,
        address: {
          "@zip": formData.zip,
          "@city": formData.city,
          "@state": formData.state,
          "@address1": "",
          "@address2": formData.address,
        },
        contactinfo: {
          "@name": " ",
          "@homephone": formData.phone,
          "@officephone": "",
          "@officeext": "",
          "@otherphone": formData.phone,
          "@othertype": "C",
          "@email": formData.email,
          "@preferredcommunicationfid": "",
        },
      },
      respparty: {
        "@name":
          !isPatient && formData.islegalguardian === "guardian"
            ? formData.submitterLastName + "," + formData.submitterFirstName
            : "",
        "@accttype": "6",
      },
      apiUrl: `${apiUrl.XMLRPC}/xmlrpc/processrequest.aspx`,
    };
    const lookupPayload= {
        exactmatch: "1",
        name : formData.lastname + "," + formData.firstname,
        apiUrl: `${apiUrl.XMLRPC}/xmlrpc/processrequest.aspx`,
    }
    const lookup = await lookupPatient(lookupPayload, dispatch);
    if(lookup?.code === 200)
    {
    let patientExists = false;
    if(lookup.data.patientlist.itemcount === "1")
    {
      if(lookup.data.patientlist.patient.dob === formData.dob.format("MM/DD/YYYY") && lookup.data.patientlist.patient.gender == birthSexValue){
        patientExists = true
      }
    }
    else if(lookup.data.patientlist.itemcount !== "0") {
      for(var i=0; i<lookup.data.patientlist.itemsto; i++)
      {
        if(lookup.data.patientlist.patient[i].dob === formData.dob.format("MM/DD/YYYY") && lookup.data.patientlist.patient[i].gender == birthSexValue) {
            patientExists = true
            break
        }
      }
    }
    if(lookup.data.patientlist.itemcount === "0" || !patientExists) {
      const response = await createOnlineSchedulerPatient(payload, dispatch);
      if (response?.code === 200) {
      const isGuardian = formData.islegalguardian === "guardian" ? 1 : 0;

      setPatient(response.data);
      setIsNext(true);
      setActiveStep(activeStep + 1);
      }
    }
    else {
      toast.error(<ExistingPatientErrorToast/>, {
        onClose: handleToastClose
      });
    }
  }
    
  };
  const handleToastClose = () => {
    // Perform page refresh here
    window.location.reload();
  }
  const ExistingPatientErrorToast = () => (
    <div>
      <span>This patient record already exists. Please use patient portal or contact <a href= "tel:+19193917202">919-391-7202</a> to schedule an appointment</span>
    </div>
  );

  const handleNextButton = async () => {
    const button= document.getElementById("second-next-button");
    button.disabled=true;  
    const selectedProfileIds =
      profileIds[`${formData.reason.name}-${formData.state}`];
    let resppartyElement = patient.resppartylist.respparty;
    localStorage.setItem(
      "patientDetails",
      JSON.stringify({
        selectedProfileIds: selectedProfileIds,
        patient: patient.patientlist.patient,
        resppartyID: resppartyElement.id.replace("resp", ""),
        reason: formData.reason,
        state: formData.state,
      })
    );
    const formDataRef = new FormData();
    formDataRef.append('Patient_fname',formData.firstname);
    formDataRef.append('Patient_lname', formData.lastname,);
    formDataRef.append('DOB', formData.dob.$d);
    formDataRef.append('Birthsex', formData.birthsex);
    formDataRef.append('Email', formData.email);
    formDataRef.append('Phone', formData.phone);
    formDataRef.append('Provider_fname', formData.submitterFirstName);
    formDataRef.append('Provider_lname', formData.submitterLastName);
    formDataRef.append('Provider_phone', formData.submitterPhone);
    formDataRef.append('Provider_email', formData.Submitteremail);
    formDataRef.append('Provider_fax', formData.Submitterfax);
    formDataRef.append('Reason', formData.reason.name);
    formDataRef.append('State', formData.state);
    formDataRef.append('DEA', formData.dea);
    formDataRef.append('Seen_patient', formData.patient_seen);
    formDataRef.append('Notes_patient', formData.clinicalNotes);
    formDataRef.append('Affiliated_company', formData.affiliatedCompany);
    fetch(
      "https://script.google.com/macros/s/AKfycbzfXyh3qVvOcvDsh6SgIK-cQ9ZrpPJ3V3S4vHRodY_kTP47V4XbX8etM0XnrFZWZo92ww/exec",
       {
        method: "POST",
        body: formDataRef
      }
    )
// 57  Initial Medication Management Consult
// 68  NY Initial Psychotherapy Consult
// 69  Georgia Initial Psychotherapy Consult
// 72  NC Initial Consult Psychotherapy
    const typeserviceid = {
      NC : 72,
      GA : 69,
      NY : 68,
      TN : 72,
    }
    const payload={
      typeOfServiceId: formData.reason.name === "Medication Management Provider" ? 57 : typeserviceid[formData.state],
      usePracticeLocation: true,
      intakeRecordStatus: 1653,
      patientId: patient.patientlist.patient.id.substring(3), 
      customData: {
            submittedDate: moment().format("MM/DD/YYYY"),
            shouldSaveCustomData: true,
            isPatient:isPatient,
            isLegalGuardian: formData.islegalguardian === "" ? false : true,
            submitterFirstName: formData.submitterFirstName,
            submitterLastName: formData.submitterLastName,
            submitterPhone: formData.submitterPhone, 
            referringProvider: formData.referringProviderName
            },
      apiUrl:apiUrl.RESTAPIPM,
    }
    const response = await intakeOnlineRequest(payload, dispatch);
    // if(response?.code===200)
    // {
    //   navigate("/view-scheduler");
    // }  
  };

  const handlebookAppointment = () => {
    navigate("/view-scheduler");
  }
  const handleKeyPress = (e, fieldType) => {
    const isNumber = e.key >= "0" && e.key <= "9";
    const isLetter =
      (e.key >= "A" && e.key <= "Z") || (e.key >= "a" && e.key <= "z");
    const isSpace = e.key === " ";
    const isComma = e.key === ",";
    const isHyphen = e.key === "-";
    const isApostrophe = e.key === "'";
    const isDigit = /\d/.test(e.key);
    const isBackspace = e.key === "Backspace";
    const inputValue = e.target.value;
    const isPeriod = e.key === ".";

    if (fieldType === "text") {
      // Allow only letters
      if (!(isLetter || isSpace)) {
        e.preventDefault();
      }
    } else if (fieldType === "referringProviderName") {
      // Allow letters, periods, commas, apostrophes, and backspace
      if (
        (isLetter || isPeriod || isComma || isApostrophe || isBackspace) &&
        !(e.ctrlKey || e.altKey)
      ) {
        return;
      }

      e.preventDefault();
    } else if (fieldType === "lastname") {
      // Allow only letters, spaces, and apostrophes
      if (!(isLetter || isSpace || isApostrophe)) {
        e.preventDefault();
      }
    } else if (fieldType === "address") {
      // Allow only letters, numbers, spaces, and commas
      if (!(isLetter || isNumber || isSpace || isComma)) {
        e.preventDefault();
      }
    } else if (fieldType === "city") {
      // Allow only letters, spaces, hyphens, and apostrophes
      if (!(isLetter || isSpace || isHyphen || isApostrophe)) {
        e.preventDefault();
      }
    } else if (fieldType === "zip") {
      // Allow typing digits or hyphen, and backspace
      if (
        (isDigit && inputValue.length < 5) ||
        (isHyphen &&
          inputValue.indexOf("-") === -1 &&
          inputValue.length === 5) ||
        (isDigit && inputValue.indexOf("-") !== -1 && inputValue.length < 10) ||
        isBackspace
      ) {
        return;
      }

      e.preventDefault();
    }
  };

   const handlePhoneInputChange = (e) => {
  // const handlePhoneInputChange = (e, field) => {
    let input = e.target.value;

    // Remove non-numeric characters
    input = input.replace(/\D/g, "");

    // Ensure the total length doesn't exceed 13 characters
    if (input.length > 10) {
      input = input.slice(0, 10);
    }

    // Insert hyphens at specific positions
    if (input.length >= 3 && input.length < 6) {
      input = `${input.slice(0, 3)}-${input.slice(3)}`;
    } else if (input.length >= 6 && input.length < 10) {
      input = `${input.slice(0, 3)}-${input.slice(3, 6)}-${input.slice(6)}`;
    } else if (input.length >= 10 && input.length < 13) {
      input = `${input.slice(0, 3)}-${input.slice(3, 6)}-${input.slice(6, 10)}`;
    }

    //setFormData({ ...formData, phone: input });
    setFormData({ ...formData, phone: input });
  };

  // Handle the case when the user presses Enter
  const handleKeyPhonePress = (e) => {
    if (e.key === "Enter") {
      // Insert the last digit and reformat
      handleInputChange({ target: { value: e.target.value + "7" } });
    }
  };

  return (
    <div className="">
      <div className="scheduler-view">
        <img src={vector} className="onlinescimg" alt="Logo" />

        <p className="scheduletitle">Refer a patient</p>
        <div className="card-schedule shadow">

          {/* {selectedOption === "new" ? ( */}
           { !isNext ? (
              <div className="mt-3">
                {/* <SelectButton
                  value={isPatient}
                  className="select-btn"
                  onChange={(e) => {
                    setIsPatient(e.target.value);
                    handleInputChange("ispatient", e.target.value);
                    if (!e.value) {
                      setFormData({
                        ...formData,
                        islegalguardian: "guardian",
                      });
                    } else {
                      setFormData({
                        ...formData,
                        islegalguardian: "",
                      });
                    }
                  }}
                  options={options}
                  style={{ display: "flex" }}
                  optionLabel="name"
                /> */}
                {/* <SelectButton
                value={isPatient}
                className="select-btn"
                onChange={(e) => {
                  //const newValue = e.target.value;
                  if (e.target.value !== isPatient) {
                     // Check if the new value is different from the current value
                    setIsPatient(e.target.value);
                    handleInputChange("ispatient", e.target.value);
                    if (!e.value) {
                      setFormData({
                        ...formData,
                        islegalguardian: "guardian",
                      });
                    } else {
                      setFormData({
                        ...formData,
                        islegalguardian: "",
                      });
                    }
                  }
                }}
                options={options}
                style={{ display: "flex" }}
                optionLabel="name"
              /> */}
                {/* {!isPatient && (
                  <>
                    {" "} */}
                    {/* <label className="sch-label mt-3">
                      <strong>I am:</strong>
                    </label>
                    <Row>
                      <Col>
                        <div>
                          <label className="sch-label">
                            <input
                              type="radio"
                              name="islegalguardian"
                              value="guardian"
                              checked={formData.islegalguardian === "guardian"}
                              onChange={(e) => {
                                handleInputChange(
                                  "islegalguardian",
                                  "guardian"
                                );
                              }}
                            />
                            <span className="radio-label-text">
                              {" "}
                              The patient's legal guardian
                            </span>
                          </label>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div>
                          <label className="sch-label">
                            <input
                              type="radio"
                              name="islegalguardian"
                              value="refer"
                              checked={formData.islegalguardian === "refer"}
                              onChange={(e) => {
                                handleInputChange("islegalguardian", "refer");
                              }}
                            />
                            <span className="radio-label-text">
                              A referring provider
                            </span>
                          </label>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div>
                          <label className="sch-label">
                            <input
                              type="radio"
                              name="islegalguardian"
                              value="other"
                              checked={formData.islegalguardian === "other"}
                              onChange={(e) => {
                                handleInputChange("islegalguardian", "other");
                              }}
                            />
                            <span className="radio-label-text">Other</span>
                          </label>
                        </div>
                      </Col>
                    </Row> */}
                    {/* {formData.islegalguardian === "guardian" && (
                      <>
                        <label className="sch-label mt-2">
                          Relation to Patient<span className="req">*</span>
                        </label>
                        <div>
                          <select
                            className="sch-input form-select form-select-sm"
                            name="relation2Patient"
                            value={formData.relation2Patient}
                            onChange={(e) =>
                              handleInputChange(
                                "relation2Patient",
                                e.target.value
                              )
                            }
                          >
                            <option value="">---Select---</option>
                            <option value="32">Mother</option>
                            <option value="33">Father</option>
                            <option value="34">Other Adult</option>
                          </select>
                          {formErrors.relation2Patient && (
                            <div className="error-message">
                              {formErrors.relation2Patient}
                            </div>
                          )}{" "}
                        </div>
                      </>
                    )} */}
                    {/* {formData.islegalguardian === "other" && (
                      <>
                        <label className="sch-label mt-2">
                          Relation to Patient<span className="req">*</span>
                        </label>
                        <div>
                          <select
                            className="sch-input form-select form-select-sm"
                            name="relation2Patient"
                            value={formData.relation2Patient}
                            onChange={(e) =>
                              handleInputChange(
                                "relation2Patient",
                                e.target.value
                              )
                            }
                          >
                            <option value="">---Select---</option>
                            <option value="01">Spouse</option>
                            <option value="32">Mother</option>
                            <option value="33">Father</option>
                            <option value="19">Child</option>
                            <option value="34">Other Adult</option>
                          </select>
                        </div>
                        {formErrors.relation2Patient && (
                          <div className="error-message">
                            {formErrors.relation2Patient}
                          </div>
                        )}
                      </>
                    )} */}
                <div>
                <label className="sch-label mt-2">
                  Patient First Name<span className="req">*</span>
                </label>
                <div>
                  <input
                    type="text"
                    placeholder="Patient First Name"
                    value={formData.firstname}
                    onKeyPress={(e) => handleKeyPress(e, "text")}
                    onChange={(e) =>
                      handleInputChange("firstname", e.target.value)
                    }
                    className="sch-input form-control"
                  />
                </div>
                <label className="sch-label mt-3">
                  Patient Last Name<span className="req">*</span>
                </label>
                <div>
                  <input
                    type="text"
                    value={formData.lastname}
                    placeholder="Patient Last Name"
                    onKeyPress={(e) => handleKeyPress(e, "lastname")}
                    onChange={(e) =>
                      handleInputChange("lastname", e.target.value)
                    }
                    className="sch-input form-control"
                  />
                </div>
                <div>
                  <Row>
                    <Col>
                      <label className="sch-label mt-3">
                        Birth Date<span className="req">*</span>
                      </label>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          value={formData.dob}
                          disableFuture={true}
                          slotProps={{
                            textField: {
                              size: "small",
                              width: "100%",
                              height: "40px",
                            },
                          }}
                          format="MM-DD-YYYY"
                          onChange={(newValue) =>
                            handleInputChange("dob", newValue)
                          }
                          onError={(newError) => setError(newError)}
                        />
                      </LocalizationProvider>
                      <span className="req">{errorMessage}</span>
                    </Col>
                    <Col>
                      <label className="sch-label mt-3">
                        Birth Sex<span className="req">*</span>
                      </label>
                      <select
                        className="sch-input form-select form-select-sm"
                        name="birthsex"
                        value={formData.birthsex}
                        onChange={(e) =>
                          handleInputChange("birthsex", e.target.value)
                        }
                      >
                        <option value="">---Select---</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="Prefer not to say">Prefer not to say</option>
                      </select>
                    </Col>
                  </Row>
                </div>
                <label className="sch-label mt-3">
                  Patient Email<span className="req">*</span>
                </label>
                <div>
                  <input
                    type="email"
                    value={formData.email}
                    placeholder="Patient Email"
                    className="sch-input form-control"
                    onChange={(e) => handleInputChange("email", e.target.value)}
                  />
                </div>
                {formData.emailError && (
                  <div className="req">{formData.emailError}</div>
                )}
                <label className="sch-label mt-3">
                  Patient Phone Number<span className="req">*</span>
                </label>
                <div>
                  <input
                    type="text"
                    placeholder="Pateint Phone Number"
                    value={formData.phone}
                    className="sch-input form-control"
                    onKeyPress={(e) => handleKeyPhonePress(e)}
                    onChange={handlePhoneInputChange}
                  />
                </div>
                <label className="sch-label mt-3">Street Address</label>
                <div>
                  <input
                    type="text"
                    value={formData.address}
                    placeholder="Street Address"
                    className="sch-input form-control"
                    onKeyPress={(e) => handleKeyPress(e, "address")}
                    onChange={(e) =>
                      handleInputChange("address", e.target.value)
                    }
                  />
                </div>
                <div>
                  <Row>
                    <Col>
                      <label className="sch-label mt-3">City</label>
                      <input
                        type="text"
                        placeholder="City"
                        value={formData.city}
                        className="sch-input form-control"
                        onKeyPress={(e) => handleKeyPress(e, "city")}
                        onChange={(e) =>
                          handleInputChange("city", e.target.value)
                        }
                      />
                    </Col>

                    <Col>
                      <label className="sch-label mt-3">Zip</label>
                      <input
                        type="text"
                        placeholder="Zip"
                        value={formData.zip}
                        className="sch-input form-control"
                        onKeyPress={(e) => handleKeyPress(e, "zip")}
                        onChange={(e) =>
                          handleInputChange("zip", e.target.value)
                        }
                      />
                    </Col>
                  </Row>
                  </div>
                  <label className="sch-label mt-3">State</label>
                  <div>
                  <select
                    className="sch-input form-select form-select-sm"
                    name="state"
                    value={formData.state}
                    onChange={(e) => handleInputChange("state", e.target.value)}
                    >
                    <option className="state-options" value="">State</option>
                    {stateOptions.map((state) => (
                      <option key={state.value} value={state.value}>
                        {state.label}
                      </option>
                    ))}
                    </select>
                  </div>
                </div>
                <div className="submitter-details">
                  <h5>Referring Provider Details</h5>
                    <label className="sch-label mt-2">
                      First Name
                    </label>
                    <div>
                      <input
                        type="text"
                        placeholder="First Name"
                        className="sch-input form-control"
                        value={formData.submitterFirstName}
                        onKeyPress={(e) => handleKeyPress(e, "text")}
                        onChange={(e) =>
                          handleInputChange(
                            "submitterFirstName",
                            e.target.value
                          )
                        }
                      />
                    </div>
                    {formErrors.submitterFirstName && (
                      <div className="error-message">
                        {formErrors.submitterFirstName}
                      </div>
                    )}
                    <label className="sch-label mt-2">
                      Last Name
                    </label>
                    <div>
                      <input
                        type="text"
                        value={formData.submitterLastName}
                        onKeyPress={(e) => handleKeyPress(e, "lastname")}
                        placeholder="Last Name"
                        className="sch-input form-control"
                        onChange={(e) =>
                          handleInputChange("submitterLastName", e.target.value)
                        }
                      />
                    </div>
                    {formErrors.submitterLastName && (
                      <div className="error-message">
                        {formErrors.submitterLastName}
                      </div>
                    )}
                    <label className="sch-label mt-2">
                      Contact Number
                    </label>
                    <div>
                      <input
                        type="text"
                        value={formData.submitterPhone}
                        placeholder="Contact Number"
                        className="sch-input form-control"
                        onKeyPress={(e) => handleKeyPhonePress(e)}
                        onChange={(e) => {
                          let input = e.target.value;

                          // Remove non-numeric characters
                          input = input.replace(/\D/g, "");

                          // Ensure the total length doesn't exceed 13 characters
                          if (input.length > 10) {
                            input = input.slice(0, 10);
                          }

                          // Insert hyphens at specific positions
                          if (input.length >= 3 && input.length < 6) {
                            input = `${input.slice(0, 3)}-${input.slice(3)}`;
                          } else if (input.length >= 6 && input.length < 10) {
                            input = `${input.slice(0, 3)}-${input.slice(
                              3,
                              6
                            )}-${input.slice(6)}`;
                          } else if (input.length >= 10 && input.length < 13) {
                            input = `${input.slice(0, 3)}-${input.slice(
                              3,
                              6
                            )}-${input.slice(6, 10)}`;
                          }

                          setFormData({ ...formData, submitterPhone: input });
                        }}
                      />
                    </div>
                    {formErrors.submitterPhone && (
                      <div className="error-message">
                        {formErrors.submitterPhone}
                      </div>
                    )}
                    <label className="sch-label mt-3">
                      Email
                    </label>
                    <div>
                      <input
                        type="email"
                        value={formData.Submitteremail}
                        placeholder="Email"
                        className="sch-input form-control"
                        onChange={(e) => handleInputChange("Submitteremail", e.target.value)}
                      />
                    </div>
                    {formData.emailErrorsub && (
                      <div className="req">{formData.emailErrorsub}</div>
                    )}

                    <label className="sch-label mt-3">
                      Fax
                    </label>
                    <div>
                      <input
                        type="text"
                        id="fax"
                        value={formData.Submitterfax}
                        placeholder="Fax"
                        className="sch-input form-control"
                        onKeyPress={(e) => handleKeyPhonePress(e)}
                        onChange={(e) => {
                          let input = e.target.value;

                          // Remove non-numeric characters
                          input = input.replace(/\D/g, "");

                          // Ensure the total length doesn't exceed 13 characters
                          if (input.length > 10) {
                            input = input.slice(0, 10);
                          }

                          // Insert hyphens at specific positions
                          if (input.length >= 3 && input.length < 6) {
                            input = `${input.slice(0, 3)}-${input.slice(3)}`;
                          } else if (input.length >= 6 && input.length < 10) {
                            input = `${input.slice(0, 3)}-${input.slice(
                              3,
                              6
                            )}-${input.slice(6)}`;
                          } else if (input.length >= 10 && input.length < 13) {
                            input = `${input.slice(0, 3)}-${input.slice(
                              3,
                              6
                            )}-${input.slice(6, 10)}`;
                          }

                          setFormData({ ...formData, Submitterfax: input });
                        }}
                      />
                    </div>

                    <label className="sch-label mt-3">Referral source</label>
                  <div>
                  <select
                    className="sch-input form-select form-select-sm"
                    name="company"
                    value={formData.affiliatedCompany}
                    onChange={(e) => {
                      if(e.target.value == "Other")
                        {
                          setotherCompany(true)
                          handleInputChange("affiliatedCompany", e.target.value)
                        } else {
                          setotherCompany(false)
                          handleInputChange("affiliatedCompany", e.target.value)}}
                        }
                    >
                      <option value="">Referral source</option>
                      {companies.map((com, index) => (
                      <option key={index} value={com}>
                      {com}
                      </option>
                    ))}
                    </select>
                    { otherCompany && (
                      <div style= {{ marginTop : "15px"}}>
                          <input
                          type="text"
                          value={formData.affiliatedCompany}
                          //onKeyPress={(e) => handleKeyPress(e, "lastname")}
                          placeholder="Referral source"
                          className="sch-input form-control"
                          onChange={(e) => 
                            handleInputChange("affiliatedCompany", e.target.value)
                          }
                        />
                      </div>
                    )
                    }
                  </div>

                  {/* </>
                )} */}
                </div>
                <div className="mt-5 d-flex justify-content-center align-items-center">
                  <button
                    className="form-submitbtn1"
                    onClick={() => {
                      handleClose();
                      clear();
                      setActiveStep(activeStep - 1);
                    }}
                  >
                    Previous
                  </button>
                  <button
                    className="form-submitbtn2"
                    id= "Next-button"
                    onClick={() => {
                      if (!formData.emailError) {
                        handleSubmit();
                      }
                    }}
                    disabled={!isFormValid()}
                  >
                    Next
                  </button>
                </div>
              </div>
            ) : (
              <>
                <label className="sch-label mt-3">
                  Reason for visit<span className="req">*</span>{" "}
                </label>
                <Row>
                  <Col>
                    <div>
                      <label className="sch-label">
                        <input
                          type="radio"
                          name="reason"
                          value={mmpID}
                          checked={formData.reason === mmpID}
                          onChange={() => handleInputChange("reason", mmpID)}
                        />
                        <span className="radio-label-text">
                          Intake for Medication Management
                        </span>
                      </label>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div>
                      <label className="sch-label">
                        <input
                          type="radio"
                          name="reason"
                          value={psyID}
                          checked={formData.reason === psyID}
                          onChange={() => handleInputChange("reason", psyID)}
                        />
                        <span className="radio-label-text">
                          Intake for Psychotherapy
                        </span>
                      </label>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div>
                      {/* <label className="sch-label">
                        <input
                          type="radio"
                          name="reason"
                          value="M"
                          checked={formData.reason === "M"}
                          onChange={() => handleInputChange("reason", "M")}
                        />
                        <span className="radio-label-text">
                          Not sure, I would like an intake to discuss my options{" "}
                        </span>
                      </label> */}
                      {/* <p className="radio-label-text">Not sure? Call us at <a href="tel:+19193917202">919-391-7207</a> to schedule a free 15-minute consultation.</p> */}
                    </div>
                  </Col>
                </Row>
                <Row style={{marginTop:"10px", marginBottom: "10px"}}>
                  <label className="sch-label">Clinical details</label>
                  <textarea
                  id="clinicalNotes"
                  name="clinicalNotes"
                  value={formData.clinicalNotes}
                  onChange={(e) => handleInputChange("clinicalNotes", e.target.value)
                  }></textarea>
                </Row>
                <label className="sch-label mt-3">
                  State of Care<span className="req">*</span>{" "}
                </label>
                <Row>
                  <Col>
                    <div>
                      <label className="sch-label">
                        <input
                          type="radio"
                          name="state"
                          value="NC"
                          checked={formData.state === "NC"}
                          onChange={() => handleInputChange("state", "NC")}
                        />
                        <span className="radio-label-text">North Carolina</span>
                      </label>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div>
                      <label className="sch-label">
                        <input
                          type="radio"
                          name="state"
                          value="GA"
                          checked={formData.state === "GA"}
                          onChange={() => handleInputChange("state", "GA")}
                        />
                        <span className="radio-label-text">Georgia</span>
                      </label>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div>
                      <label className="sch-label">
                        <input
                          type="radio"
                          name="state"
                          value="TN"
                          checked={formData.state === "TN"}
                          onChange={() => handleInputChange("state", "TN")}
                        />
                        <span className="radio-label-text">Tennessee</span>
                      </label>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div>
                      <label className="sch-label">
                        <input
                          type="radio"
                          name="state"
                          value="NY"
                          checked={formData.state === "NY"}
                          onChange={() => handleInputChange("state", "NY")}
                        />
                        <span className="radio-label-text">New York</span>
                      </label>
                    </div>
                  </Col>
                </Row>
                <Row>
                <label className="sch-label mt-3">
                Is the referring provider a DEA registered provider? (<i>Optional</i>)
                </label>
                <div>
                  <label className="sch-label">
                  <input
                    type="radio"
                    name="dea"
                    value="Yes"
                    onChange={() => handleInputChange("dea", "Yes")}
                  />
                  <span className="radio-label-text">Yes</span>
                  </label>

                  <label className="sch-label">
                  <input
                    type="radio"
                    name="dea"
                    value="No"
                    onChange={() => handleInputChange("dea", "No")}
                  />
                  <span className="radio-label-text">No</span>
                  </label>
                  
                  <label className="sch-label">
                  <input
                    type="radio"
                    name="dea"
                    value="Don't know"
                    onChange={() => handleInputChange("dea", "Don't know")}
                  />
                  <span className="radio-label-text">Don't know</span>
                  </label>
                  
                </div>
                </Row>
                <label className="sch-label mt-3">
                Did the referring provider see the patient in-person?
                </label>
                <Row>
                <label className="sch-label">
                  <input
                    type="radio"
                    name="meet"
                    value="Yes"
                    onChange={() => handleInputChange("patient_seen", "Yes")}
                  />
                  <span className="radio-label-text">Yes</span>
                  </label>
                </Row>
                <Row>
                <label className="sch-label">
                  <input
                    type="radio"
                    name="meet"
                    value="virtual"
                    onChange={() => handleInputChange("patient_seen", "virtual")}
                  />
                  <span className="radio-label-text">No, my evaluations of this patient have been virtual</span>
                  </label>
                </Row>
                <Row>
                <label className="sch-label">
                  <input
                    type="radio"
                    name="meet"
                    value="never"
                    onChange={() => handleInputChange("patient_seen", "No")}
                  />
                  <span className="radio-label-text">No, I have never evaluated this patient</span>
                  </label>
                </Row>
                
                
                {/* <label className="sch-label mt-3">Upload patient file</label>
                  <div>
                  <Toast ref={toast}></Toast>
                  <FileUpload mode="basic" name="demo[]" url="/api/upload" accept="image/*" maxFileSize={1000000} onUpload={console.log("got it")} />
                  </div> */}

                <div className="mt-5 d-flex justify-content-center align-items-center">
                  <button
                    className="form-submitbtn1"
                    onClick={() => {
                      setIsNext(false);
                      setActiveStep(activeStep - 1);
                    }}
                  >
                    Previous
                  </button>
                  <button
                    className="form-submitbtn2"
                    id="second-next-button"
                    onClick={() => {
                      setIsNext(true);
                      setActiveStep(activeStep + 1);
                      handleNextButton();
                      setShowModel(true)
                    }}
                     disabled={!formData.state || !formData.reason}
                  >
                    Send
                  </button>
                  {
                    showmodel && (
                      <Modal centered show={showmodel} onHide={handleClose} animation={false} backdrop="static" keyboard={false}>
                      <Modal.Header closeButton></Modal.Header>
                      <Modal.Body>
                        <p>
                          Thank you! We have received the patient information. We will be happy to contact the patient and schedule an appointment. <b>Alternatively, if you know a slot that works for the patient, please go ahead and schedule an appointment.</b>
                        </p>
                      </Modal.Body>
                      <div className="footer-btn d-flex justify-content-center align-items-center">
                        <Button
                          variant="outline-primary"
                          style={{ width: "90px" , marginRight: "20px"}}
                          onClick={() => {
                            handleClose();
                            window.location.href = "https://animosanopsychiatry.com/";
                          }}
                        >
                          Exit
                        </Button>
                        <Button
                          variant="apt-confirm-btn"
                          style={{ backgroundColor: "#2B6CB0", color: "#FFFFFF", marginLeft: "20px"}}
                          onClick={handlebookAppointment}
                        >
                          Book Appointment
                        </Button>
                      </div>
                    </Modal>
                    )
                  }
                </div>
              </>
            )}
          {/* ) : (
            <div className="scheduler-block">
              <label className="sch-label">Choose one option</label>
              <Row>
                <Col>
                  <div>
                    <label className="sch-label">
                      <input
                        type="radio"
                        name="holdreasonid"
                        value="new"
                        checked={selectedOption === "new"}
                        onChange={() => {
                          handleRadioChange("new");
                          setActiveStep(1);
                        }}
                      />
                      <span className="radio-label-text">New Patient</span>
                    </label>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div>
                    <label className="sch-label">
                      <input
                        type="radio"
                        name="holdreasonid"
                        value="returning"
                        checked={selectedOption === "returning"}
                        onChange={() => {
                          handleRadioChange("returning");
                          handleShow();
                          setActiveStep(1);
                        }}
                      />
                      <span className="radio-label-text">
                        Returning Patient
                      </span>
                    </label>
                  </div>
                </Col>
              </Row>
            </div>
          )} */}
        </div>
      </div>

      {/* {selectedOption === "returning" && (
        <Modal centered show={showmodel} onHide={handleClose} animation={false}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <p>
              Please use the patient portal for scheduling. You can log in to
              the portal by&nbsp;
              <a href="https://pp-wfe-102.advancedmd.com/account/logon?lk=149583">
                <b>
                  <u>going here</u>
                </b>
              </a>
              . If you have lost access to your portal or have forgotten your
              account information please call us at&nbsp;
              <a href="tel:+19193917202">
                <b>
                  <u>919-391-7202</u>
                </b>
              </a>
              (Select option 1 for support).
            </p>
          </Modal.Body>
          <div className="footer-btn d-flex justify-content-center align-items-center">
            <Button
              variant="outline-primary"
              style={{ marginRight: "40px", width: "90px" }}
              onClick={handleClose}
            >
              Back
            </Button>
            <Button
              variant="outline-primary"
              style={{ width: "90px" }}
              onClick={() => {
                handleClose();
                window.location.href = "https://animosanopsychiatry.com/";
              }}
            >
              Exit
            </Button>
          </div>
        </Modal>
      )} */}
    </div>
  );
};

export default OnlineScheduler;
