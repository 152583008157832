import React from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { editAppointment, schedulerCreateAppointment, sendConfirmationEMail } from "../../services/onlinescheduler.service";
import { useDispatch } from "react-redux";
import {getEpisodes} from "../../services/onlinescheduler.service";

const AppointmentDetails = (props) => {
  const navigate = useNavigate();
  const apiUrlString = localStorage.getItem("schedulerUrl");
  const patientDetails=localStorage.getItem("patientDetails");
  const apiUrl = apiUrlString ? JSON.parse(apiUrlString) : null;
  const patientDetailsJSON = apiUrlString ? JSON.parse(patientDetails) : null;
  const dispatch = useDispatch();
  

  const handleConfirmAppointment = async () => {
    const patientData = {
      patientid: props.selectedAppointmentSlot.patientId,
      apiUrl: `${apiUrl.XMLRPC}/xmlrpc/processrequest.aspx`,
    };
    const response = await getEpisodes(patientData, dispatch);
    if(response?.code===200)
    {
      const payload = {
        patientid: props.selectedAppointmentSlot.patientId,
        columnid: props.selectedAppointmentSlot.column.id,
        startdatetime: moment(
        props.selectedAppointmentSlot.slot.startdatetime
        ).format("YYYY-MM-DDTHH:mm:ss"),
        duration: props.selectedAppointmentSlot.type[0]?.appttypesettings[0]?.duration,
        //color: "YELLOW",
        color: props.selectedAppointmentSlot.type[0].id === "1473" ? "YELLOW" : "BLUE" ,
        profileid: props.selectedAppointmentSlot.profile.id,
        type: props.selectedAppointmentSlot.type[0].id === "1473" ?  [
              {
                  "id": 1473, 
                  "name": "TELE VISIT INITIAL VISIT",
                  "isdefault": true, 
                  "billable": true,
                  "transitionofcare": false,
                  "istelemedicine": true,
                  "display": true,
                  "isgroupappt": false,
                  "settings": [
                      {
                          "id": 792, 
                          "profileid": null,
                          "chargesliptemplateid": 26,
                          "duration": 75,
                          "color": "YELLOW",
                          "hide": false,
                          "priority": "Medium",
                          "groupapptmaxpat": null,
                          "instructions": [
                              10,
                              11,
                              28,
                              41,
                              47,
                              48
                          ]
                      }
                  ],
                  "$selected": false,
              }
          ] : [
            {
              "id": 1661, 
              "name": "TELE VISIT INITIAL PSYCHOTHERAPY",
              "isdefault": true, 
              "billable": true,
              "transitionofcare": false,
              "istelemedicine": true,
              "display": true,
              "isgroupappt": false,
              "settings": [
                  {
                      "id": 1328, 
                      "profileid": null,
                      "chargesliptemplateid": 26,
                      "duration": 60,
                      "color": "BLUE",
                      "hide": false,
                      "priority": "Medium",
                      "groupapptmaxpat": null,
                      "instructions": [
                          11,
                          28,
                          41,
                          48
                      ]
                  }
              ],
              "$selected": false,
          }
          ]
          ,
          instruction: [
            {
                "id": 10,
                "name": "FORMS",
                "instruction": "PLEASE COMPLETE ANY PATIENT FORMS ASSIGNED TO YOU AT LEAST 1 DAY PRIOR TO THE START OF YOUR APPOINTMENT.",
                "display": true,
                "selected": false
            },
            {
                "id": 11,
                "name": "ID",
                "instruction": "PLEASE BRING A VALID ID TO YOUR APPOINTMENT.",
                "display": true,
                "selected": false
            },
            {
                "id": 28,
                "name": "PAYMENT",
                "instruction": "PLEASE PAY OUTSTANDING APPIONTMENT BILLS PRIOR TO YOUR VISIT.",
                "display": true,
                "selected": false
            },
            {
                "id": 41,
                "name": "TELEHEALTH",
                "instruction": "FOR TELEHEALTH VISITS PLEASE BE SURE TO GO TO OUR VIRTUAL WAITING ROOM 5 MINUTES PRIOR TO YOUR VISIT: HTTPS://ASPSYCHIATRY.DOXY.ME/.",
                "display": true,
                "selected": false
            },
            {
                "id": 47,
                "name": "VITALS",
                "instruction": "PLEASE BE SURE TO RECORD YOUR MOST RECENT BLOOD PRESSURE, HEART RATE, AND WEIGHT PRIOR TO YOUR VISIT.",
                "display": true,
                "selected": false
            },
            {
                "id": 48,
                "name": "INS.CARD",
                "instruction": "DO YOU HAVE NEW OR UPDATED INSURANCE? IF SO, PLEASE UPLOAD THE FRONT AND BACK OF YOUR INS. CARD VIA YOUR PATIENT PORTAL. ONCE UPLOADED, SEND A PORTAL MESSAGE TO OUR ADMIN TEAM SO WE CAN PROMPTLY UPDATE YOUR ACCOUNT FOR BILLING",
                "display": true,
                "selected": false
            }
      ],
        episodeid: response.data.patientlist.patient.episodelist.episode.id.substring(3),
        transitionofcare: true,
        tocrequestedavailable: null,
        istelemedicine: true,
        iswaitlist: false,
        facilityid: props.selectedAppointmentSlot.column.facilityid
          ? props.selectedAppointmentSlot.column.facilityid
          : 1,
        referralplanid: response.data.patientlist.patient.episodelist.episode.refplan.substring(5),
        apiUrl: apiUrl.RESTAPIPM,
      };
      props.setShowProviderModel(false);
  
      const result = await schedulerCreateAppointment(payload, dispatch);
      if (result?.code === 200) {
        const fullName = patientDetailsJSON.patient.name;
        const [lastName, firstName] = fullName.split(',');
        const formDataPat = new FormData();
        formDataPat.append('Source', "Online Intake");
        formDataPat.append('Date_received', moment().format("MM/DD/YYYY"));
        formDataPat.append('Time_received', moment().format("HH:mm:ss"));
        formDataPat.append('Patient_id', patientDetailsJSON.patient.id.substring(3))
        formDataPat.append('First_name', firstName)
        formDataPat.append('Last_name', lastName)
        formDataPat.append('DOB', patientDetailsJSON.patient.dob)
        formDataPat.append('Birthsex', patientDetailsJSON.patient.sex)
        formDataPat.append('Phone', patientDetailsJSON.patient.contactinfo.homephone)
        formDataPat.append('Email', patientDetailsJSON.patient.contactinfo.email)
        formDataPat.append('Scheduled', "Yes")
        formDataPat.append('Appointment_date', moment(
          props.selectedAppointmentSlot.slot.startdatetime
          ).format("MM/DD/YYYY"));
        formDataPat.append("Preferred_provider", props.selectedAppointmentSlot.profile.name);
        fetch(
          "https://script.google.com/macros/s/AKfycbzJk94G4mBT29dlXClYgfmLyHy4DCWLpsFqxqNuFfpquvtIw3gUE9hWQ0poISujKfwd2Q/exec",
          {
            method: "POST",
            body: formDataPat
          }
        )
        localStorage.setItem('apptdatetime', result.data.startdatetime);
        const mailOptions ={
          to: patientDetailsJSON.patient.contactinfo.email,
          appt: {
            startdatetime: result.data.startdatetime,
            provider: props.selectedAppointmentSlot.profile.name,
          }
        }
        const confirmation =  await sendConfirmationEMail(mailOptions, dispatch);
        if(confirmation?.code === 200)
        {
        navigate("/view-scheduler/success");
        }
       }
    } 
  };

  return (
    <div>
      {" "}
      <Modal
        centered
        show={props.showproviderModel}
        onHide={() => {
          props.setShowProviderModel(false);
        }}
      >
        <Modal.Header className="view-header" closeButton>
          <Modal.Title style={{ fontSize: "18px", fontWeight: 500 }}>
            Appointment Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="view-body">
          <div className="row">
            <p>You are scheduling an appointment with</p>
          </div>
          <Row>
            <Col>
              <h6 className="mt-3">
                {props?.selectedAppointmentSlot.profile.name}
              </h6>
              <a href="https://animosanopsychiatry.com/" target="_blank" >ANIMO SANO PSYCHIATRY PLLC</a>
              <p>on</p>
              <h6>
                {moment(
                  props?.selectedAppointmentSlot.slot.startdatetime
                ).format("dddd, MMMM D, YYYY [at] h:mm A")}
              </h6>
              <p>for</p>
              <p>{props?.selectedAppointmentSlot?.type[0]?.name}</p>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Col>
            <button
              className="back-btn"
              onClick={() => {
                props.setShowProviderModel(false);
              }}
            >
              Back
            </button>
          </Col>
          <Col>
            <button
              className="apt-confirm-btn"
              style={{ display: 'flex', alignItems: 'center' }}
              onClick={() => {
                handleConfirmAppointment();
              }}
            >
              <i className="fa-solid fa-video"></i> Confirm Telehealth Appt
            </button>
          </Col>{" "}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AppointmentDetails;
