import moment from "moment";
import React, { useEffect, useState } from "react";
import "./index.scss";
import { Col, Container, Dropdown, Modal, Row } from "react-bootstrap";
import { Dropdown as Dropdown2 } from "primereact/dropdown";
import vector from "../../assets/images/schedulerlogo.png";
import { Calendar as Calendar2 } from "primereact/calendar";
import Avatar from "react-avatar";
import AppointmentDetails from "./AppointmentDetails";
import { useLocation } from "react-router-dom";
import {
  listOfAppointments,
  listOfBlockHolds,
  listOfColumns,
  schedulerAppointmentTypesList,
} from "../../services/onlinescheduler.service";
import { useDispatch } from "react-redux";
import { fullPageStop } from "../../slice/loader.slice";
import { Calendar } from "primereact/calendar";

const ViewScheduler = () => {
  const location = useLocation();
  const patientDetails = localStorage.getItem("patientDetails");
  const { selectedProfileIds, patient, reason, state } =
    JSON.parse(patientDetails);
  const profileIds = JSON.parse(localStorage.getItem("profileIds"));
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [showproviderModel, setShowProviderModel] = useState(false);
  const apiUrlString = localStorage.getItem("schedulerUrl");
  const [profiles, setProfiles] = useState(selectedProfileIds);
  const [filteredProfiles, setFilteredProfiles] = useState([]);
  const [sortedProfiles, setSortedrofiles] = useState([]);
  const [columns, setColumns] = useState([]);
  const apiUrl = apiUrlString ? JSON.parse(apiUrlString) : null;
  const dispatch = useDispatch();
  const [availableSlots, setAvailableSlots] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [showNextButton, setShowNextButton] = useState(false);
  const [showError, setShowError] = useState(false);
  const [selectedAppointmentSlot, setSelectedAppointmentSlot] = useState(null);
  const [appointmentType, setAppointmentType] = useState([]);
  const [filterData, setFilterData] = useState({
    selectedReason: reason.name,
    selectedState: state,
    selectedProvider: "",
    selectedDate: null,
  });
  let attempt = 0;
  const patientId = patient.id.replace("pat", "");
  const handleInputChange = (name, value) => {
    setFilterData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleShowSlots = () => {
    const selectedProfileIds =
      profileIds[`${filterData?.selectedReason}-${filterData?.selectedState}`];
    // If a specific provider is selected, filter the profileIds further
    const filteredProfileIds =
      filterData?.selectedProvider && filterData.selectedProvider !== ""
        ? filteredProfiles?.filter(
            (profileId) => profileId.id == filterData.selectedProvider
          )
        : selectedProfileIds;

    if (filterData.selectedDate) {
      getAllBlockHolds(
        filterData.selectedDate,
        filteredProfileIds,
        filterData.selectedReason,
        columns,
        appointmentType
      );
      setSelectedDate(filterData.selectedDate);
      setCurrentDate(filterData.selectedDate);
    } else {
      getAllBlockHolds(
        new Date(),
        filteredProfileIds,
        filterData.selectedReason,
        columns,
        appointmentType
      );
    }
    setProfiles(filteredProfileIds);
  };

  useEffect(() => {
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", function (event) {
      window.history.pushState(null, document.title, window.location.href);
    });
  }, []);

  /**Call function when we change the date range */
  const handleNavigate = (action) => {
    const newDate = moment(currentDate).add(action === "PREV" ? -1 : 1, "day");
    setSelectedDate(newDate);
    setCurrentDate(newDate.toDate());
    getAllBlockHolds(
      newDate,
      profiles,
      filterData.selectedReason,
      columns,
      appointmentType
    );
  };

  const handleDateChange = (e) => {
    const selectedDate = new Date(e.target.value);
    setSelectedDate(selectedDate);
    getAllBlockHolds(
      selectedDate,
      profiles,
      filterData.selectedReason,
      columns,
      appointmentType
    );
    // Set the current date to the selected date
    setCurrentDate(selectedDate);
  };

  useEffect(() => {
    getAllRequiredList();
  }, []);

  /**Get All api's which is required */
  const getAllRequiredList = async () => {
    const typeData = await schedulerAppointmentTypesList(
      dispatch,
      apiUrl.XMLRPC
    );
    setAppointmentType(typeData?.appttypelist?.appttype);
    const columnData = await listOfColumns(dispatch, apiUrl.RESTAPIPM);
    setColumns(columnData);
    if (columnData && typeData) {
      getAllBlockHolds(
        new Date(),
        profiles,
        filterData.selectedReason,
        columnData,
        typeData?.appttypelist?.appttype
      );
      setProfiles(selectedProfileIds);
      setFilteredProfiles(selectedProfileIds);
    }
  };

  //Call function when click on video call button
  const handleVideoButtonClick = async (slot, profile, column) => {
    const type = appointmentType.filter(
      (type) => slot.appointmenttypeid === parseInt(type.id)
    );
    const payload = {
      slot,
      type,
      patientId,
      profile,
      column,
    };
    setSelectedAppointmentSlot(payload);
    setShowProviderModel(true);
  };

  /**Get the all blockholds list */
  const getAllBlockHolds = async (
    date,
    profiles,
    reason,
    columns,
    appointmentType
  ) => {
    let count = 0;
    // Assuming profileIDs is an object with arrays as values
    const profileIDsArray = profiles?.map((item) => {
      return item.id;
    });
    const matchingColumns =
      columns &&
      columns
        .filter(
          (column) =>
            column.profileid !== null &&
            profileIDsArray?.includes(column.profileid.toString())
        )
        .map((matchingColumn) => matchingColumn);

    const resourceIdArray = matchingColumns?.map(function (item) {
      return item.id;
    });
    const concatenatedString = resourceIdArray?.join("-");
    const blockholds = await listOfBlockHolds(
      {
        startDate: moment(date).format("YYYY-MM-DD"),
        columnId: concatenatedString,
        forView: "day",
      },
      apiUrl.RESTAPIPM,
      dispatch
    );
    const appt = await listOfAppointments(
      {
        columnId: concatenatedString,
        forView: "day",
        startDate: moment(date).format("YYYY-MM-DD"),
      },
      apiUrl.RESTAPIPM,
      dispatch
    );

    // Filter out past time slots
    //const currentDate = new Date();
    // const filteredBlockholds = blockholds?.filter(
    // (item) => new Date(item.startdatetime) >= currentDate
    // );
    const currentDate = new Date();
    const twoHoursLater = new Date(currentDate.getTime() + 2 * 60 * 60 * 1000); // Add 2 hours in milliseconds
   
    // Moved the filter to line 309
    // const filteredBlockholds = blockholds?.filter(
    //     (item) => new Date(item.startdatetime) >= twoHoursLater
    //   );

    const result = [];
    // filteredBlockholds?.forEach((item) => {
      blockholds?.forEach((item) => {
      if (item.reasonid === 2254) {
        let date = new Date(item.startdatetime).toDateString();
        let columnId = item.column.id;

        if (!result[columnId]) {
          result[columnId] = {};
        }
        if (!result[columnId][date]) {
          result[columnId][date] = [];
        }

        const startdatetime = new Date(item.startdatetime);

        // Filter blockholds to include only blocks with specified reasons
        // const filteredBlocks = filteredBlockholds?.filter(
          const filteredBlocks = blockholds?.filter(
          (block) =>
          // Added reasonid 2252- Supervisor meeting 2257- PTO(Paid time off) 2247- New Provider Training Day 
          // 2232- No new patients/follow-ups only 2241- Makeup admin time 2245- Blocked for rescheduling
          // 2253- Psychoeducationgroup 4- Staff meeting 2269- Provider Sick out 3- Conference 2255- TMS Consulting block
          // Changes made by Monica Hegde on 3/15/2024
            [8, 2231, 2233, 6, 2, 2252, 2257, 2247, 2232, 2241, 2245, 2253, 4, 2269, 3, 2255].includes(block.reasonid) &&
            block.column.id === columnId
        );

        // Check if any block has a conflicting startdatetime with the current hold for the same column
        const hasConflictingBlock = filteredBlocks?.some(
          (block) =>
            new Date(block.startdatetime).getTime() === startdatetime.getTime()
        );

        // Check if startdatetime and columnId already exist in the appt array, if not, add to result array
        const isStartDatetimeInAppt = appt?.some(
          (apptItem) =>
            apptItem.startdatetime === startdatetime &&
            apptItem.columnid === columnId
        );

        // Find the appointment type for the current hold
        const type = appointmentType?.find(
          (type) => item.appointmenttypeid === parseInt(type.id)
        );
        // Calculate the end datetime of the hold
        const holdEnd = new Date(
          startdatetime.getTime() + type?.appttypesettings[0]?.duration * 60000
        );

        // Check if the hold overlaps with any appointment in the same column
        const isOverlappingBlockhold = filteredBlocks?.some((item) => {
          const Start = new Date(item.startdatetime);
          const End = new Date(Start.getTime() + item.duration * 60000); // Convert duration to milliseconds
          return startdatetime < End && holdEnd > Start;
        });

        // Filter appointments for the same column
        const apptForColumn = appt?.filter(
          (apptItem) => apptItem.columnid === columnId
        );

        // Check if the hold overlaps with any appointment in the same column
        const isOverlapping = apptForColumn?.some((apptItem) => {
          const apptStart = new Date(apptItem.startdatetime);
          const apptEnd = new Date(
            apptStart.getTime() + apptItem.duration * 60000
          ); // Convert duration to milliseconds

          // Check if holdStart is before apptEnd and holdEnd is after apptStart
          return startdatetime < apptEnd && holdEnd > apptStart;
        });

        // Check if the item already exists in the result array for the same columnId and startdatetime
        const isItemAlreadyAdded = result[columnId][date].some(
          (existingItem) =>
            existingItem.startdatetime.getTime() === startdatetime.getTime()
        );

        if (!isItemAlreadyAdded) {
          // Only add if there are no conflicting blocks, the appointment slot is not already booked, and there's no overlap with appointments
          if (
            !hasConflictingBlock &&
            !isStartDatetimeInAppt &&
            !isOverlapping &&
            !isOverlappingBlockhold && startdatetime >= twoHoursLater
          ) {
            result[columnId][date].push({
              startdatetime: startdatetime,
              duration: type?.appttypesettings[0]?.duration,
              color: item.color,
              appointmenttypeid: item.appointmenttypeid,
              reasonid: item.reasonid,
            });

            // Check if the array for the current date is non-empty before incrementing count
            if (result[columnId][date].length > 0) {
              count++;
            }
          }
        }
      }
    });
    setAvailableSlots(result);

    const sortedProfiles = profiles?.sort((profileA, profileB) => {
      const columnsA = columns?.filter(
        (column) => column.profileid == profileA.id
      );
      const columnsB = columns?.filter(
        (column) => column.profileid == profileB.id
      );

      const slotsLengthA = columnsA.reduce(
        (total, column) =>
          total +
          (result[column.id]
            ? Object.values(result[column.id]).reduce(
                (valueTotal, value) => valueTotal + value.length,
                0
              )
            : 0),
        0
      );

      const slotsLengthB = columnsB.reduce(
        (total, column) =>
          total +
          (result[column.id]
            ? Object.values(result[column.id]).reduce(
                (valueTotal, value) => valueTotal + value.length,
                0
              )
            : 0),
        0
      );

      // Sort profiles based on the total length of slots across all columns
      return slotsLengthB - slotsLengthA;
    });

    setSortedrofiles(sortedProfiles);

    if (count === 0 && attempt < 30) {
      attempt++;
      const nextWeekDate = moment(date).add(1, "day").format("YYYY-MM-DD");
      await getAllBlockHolds(
        nextWeekDate,
        profiles,
        reason,
        columns,
        appointmentType
      );
    } else if (count > 0) {
      setSelectedDate(date);
      setCurrentDate(date);
    } else if (attempt === 30) {
      setSelectedDate(date);
      setCurrentDate(date);
      setShowError(true);
    }
    dispatch(fullPageStop());
  };

  return (
    <div className="main-wrapper">
      <Container fluid className="onlineScheduler">
        {" "}
        <Row>
          <Col md={3}>
            <div>
              <div className="col-sm-5 onlinesc">
                <img src={vector} className="onlinesc-img" alt="Logo" />
              </div>
            </div>
          </Col>
          <Col className="calendar-cols">
            <div className="calendar-toolbar">
              <button
                className="arrowbutton"
                disabled={moment(currentDate).isSameOrBefore(
                  moment().startOf("day"),
                  "day"
                )}
                onClick={() => handleNavigate("PREV")}
              >
                <i className="fa-solid fa-chevron-left"></i>
              </button>
              <Calendar
                className="input-date"
                showIcon
                minDate={new Date()}
                onChange={(e) => handleDateChange(e)}
              />

              <span style={{marginRight:'8px'}}>{`${moment(selectedDate ? selectedDate : new Date()).format(
                "MM-DD-YYYY"
              )}`}</span>
              <button
                className="arrowbutton"
                onClick={() => handleNavigate("NEXT")}
              >
                <i className="fa-solid fa-chevron-right"></i>
              </button>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={1} xs={2} style={{width: '100%' }}>
          <p className="view-schedule-msg">Please select a time slot to schedule your visit or click "<span className="highlight">Find Appointment</span>" to search for a different time or provider.</p>
          <div  className="view-schedule-msgnxt"><p><i class="fas fa-info-circle"></i> Appointment will approximately be 60 minutes.</p></div>
          </Col>
        </Row>
        <Row>
          <Col md={1} xs={2} style={{width: '33%'}}>
            <div className="filter-btn" style={{ height: "50px" }}>
              <div className="apt-btn me-3">
                <Dropdown
                  show={isDropdownOpen}
                  autoClose={false}
                  onToggle={(isOpen) => setDropdownOpen(isOpen)}
                >
                  <Dropdown.Toggle
                    as="div"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <button className="apt-btn" style={{ display: 'flex', alignItems: 'center' }}>
                      <i className="fa fa-search" aria-hidden="true" style={{ marginLeft: '8px' }}></i>
                      <span style={{ marginLeft: '8px' }}>Find Appointment</span>
                    </button>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="filter-dropdown">
                    <Row>
                      <Col>
                        <h5>Find Appointment Times</h5>
                      </Col>
                      <Col className="text-end col-2">
                        <a
                          className="icon-block"
                          onClick={() => {
                            setFilterData({
                              selectedReason: reason.name,
                              selectedState: state,
                              selectedProvider: "",
                              selectedDate: null,
                            });
                            setDropdownOpen(false);
                            setCurrentDate(new Date());
                            setSelectedDate(new Date());
                            setFilteredProfiles(selectedProfileIds);
                            setProfiles(selectedProfileIds);
                            getAllBlockHolds(
                              new Date(),
                              selectedProfileIds,
                              reason.name,
                              columns,
                              appointmentType
                            );
                          }}
                        >
                          <i
                            className="fa-solid fa-xmark"
                            aria-hidden="true"
                          ></i>
                        </a>
                      </Col>
                    </Row>
                    <Row>
                      <Dropdown2
                        value={filterData.selectedReason}
                        options={[
                          {
                            label:
                              "Intake and medication management consultation",
                            value: "Medication Management Provider",
                          },
                          {
                            label: "Intake and psychotherapy consultation",
                            value: "Psychotherapist",
                          },
                        ]}
                        onChange={(e) => {
                          const key = `${e.value}-${filterData.selectedState}`;
                          const profile = profileIds[key];
                          setFilteredProfiles(profile);
                          setFilterData((prevData) => ({
                            ...prevData,
                            selectedProvider: "",
                            selectedReason: e.value, // Update selectedReason
                          }));
                        }}
                        placeholder="Select Reason"
                        optionLabel="label"
                        style={{ margin: "10px", width: "95%" }}
                        className="sch-input"
                      />
                    </Row>
                    <Row>
                      <Dropdown2
                        value={filterData.selectedState}
                        options={[
                          { label: "North Carolina", value: "NC" },
                          { label: "Georgia", value: "GA" },
                          { label: "Tennessee", value: "TN" },
                          { label: "New York", value: "NY" },
                        ]}
                        onChange={(e) => {
                          setFilterData((prevData) => ({
                            ...prevData,
                            selectedProvider: "",
                            selectedState: e.value, // Update selectedState
                          }));
                          const key = `${filterData.selectedReason}-${e.value}`;
                          const profile = profileIds[key];
                          setFilteredProfiles(profile);
                        }}
                        placeholder="Select State"
                        optionLabel="label"
                        style={{ margin: "10px", width: "95%" }}
                        className="sch-input"
                      />
                    </Row>
                    <Row>
                      <Dropdown2
                        value={filterData?.selectedProvider}
                        onChange={(e) => {
                          handleInputChange("selectedProvider", e.value);
                        }}
                        options={filteredProfiles}
                        filter
                        showClear
                        optionLabel="name"
                        optionValue="id"
                        style={{ margin: "10px", width: "95%" }}
                        placeholder="Select Provider"
                        className="sch-input"
                      />
                    </Row>
                    <Row>
                      <Calendar2
                        className="sch-input"
                        placeholder="Appointment Date"
                        value={filterData.selectedDate}
                        onChange={(e) => {
                          handleInputChange("selectedDate", e.target.value);
                        }}
                        showIcon
                        minDate={new Date()}
                      />
                    </Row>
                    <button
                      className="filterApply mt-2"
                      onClick={() => {
                        handleShowSlots();
                        setDropdownOpen(false);
                      }}
                      disabled={filteredProfiles?.length > 0 ? false : true}
                    >
                      Show Available Slots
                    </button>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </Col>
          {/* <Col xs={10} md={3}> */}
            {/* <p className="heading">Telehealth</p> */}
          {/* </Col> */}
          <Col md={8} className="calendar-col">
            <table className="simple-calendar">
              <thead>
                <tr>
                  <th>
                    {moment(selectedDate ? selectedDate : new Date()).format(
                      "D"
                    )}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="d-sm-flex">
                    {moment(selectedDate ? selectedDate : new Date()).format(
                      "dddd"
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
        </Row>
        {sortedProfiles?.map((profile, index) => {
          // Find matching columns for the current profile
          const matchingColumns = columns?.filter(
            (column) => column.profileid == profile.id
          );

          return (
            <div key={index} className="row-container">
              <Row className="mt-2 mt-sm-5">
                <Col md={1} className="cols d-sm-block">
                  {/* <Avatar name={profile.name} round={true} size={70} /> */}
                  <Avatar alt={profile.name} round={true} src={`/public/providers/${profile.id}.png`} size={80} />
                </Col>
                <Col md={3} className="cols-name">
                  <h6 className="names mt-3">{profile.name}</h6>
                  <p>{profile.title}</p>
                  {/* <a href="">ANIMO SANO PSYCHIATRY PLLC</a> */}
                </Col>
                <Col md={8} className="mt-3 mt-sm-0">
                  {matchingColumns?.map((column, colIndex) => {
                    const matchingSlotsForColumn = availableSlots[column.id];
                    const dateKey =
                      moment(selectedDate).format("ddd MMM DD YYYY");
                    const matchingSlots =
                      matchingSlotsForColumn && matchingSlotsForColumn[dateKey];
                    const hasSlots = matchingSlots && matchingSlots.length > 0;

                    return (
                      <Row key={colIndex}>
                        <Col className="slots">
                          {hasSlots && (
                            <>
                              {matchingSlots?.map((slot, slotIndex) => (
                                <button
                                  key={slotIndex}
                                  className="videobtn"
                                  onClick={() =>
                                    handleVideoButtonClick(
                                      slot,
                                      profile,
                                      column
                                    )
                                  }
                                >
                                  <i className="fa-solid fa-video"></i>{" "}
                                  {moment(slot.startdatetime).format("hh:mm A")}
                                </button>
                              ))}
                            </>
                          )}
                        </Col>
                      </Row>
                    );
                  })}
                </Col>
              </Row>
              <hr />
            </div>
          );
        })}
      </Container>
      {showError && (
        <Modal
          centered
          show={showError}
          onHide={() => {
            setShowError(false);
          }}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <p>
              Sorry, we're fully booked for the next 30 days. To book beyond
              this period, please click the right arrow {">"} near the date
              display.
            </p>
          </Modal.Body>
        </Modal>
      )}
      {showproviderModel && (
        <AppointmentDetails
          setShowProviderModel={setShowProviderModel}
          showproviderModel={showproviderModel}
          selectedAppointmentSlot={selectedAppointmentSlot}
          profileIds={location.state}
        />
      )}
    </div>
  );
};

export default ViewScheduler;
