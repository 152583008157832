import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "../src/assets/scss/style.css";
import { publicRoutes } from "./routes/publicRoutes";
import NotFound from "./pages/PageNotFound/NotFound";
import React, { Suspense, useEffect } from "react";
import FullpageLoader from "./components/FullPageLoader";
import { ToastContainer } from "react-toastify";

const App = () => {
  let routes = publicRoutes;

  const handleHomeRoute = () => {
    return "/scheduler";
  };

  useEffect(() => {
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', function (event){
        window.history.pushState(null, document.title,  window.location.href);
    });
  }, []);
  
  return (
    <BrowserRouter>
      <Suspense fallback={<FullpageLoader />}>
        <Routes>
          {routes?.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={
                <route.layout>
                  <route.component />
                </route.layout>
              }
            />
          ))}
          <Route path="/" element={<Navigate to={handleHomeRoute()} />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
      <ToastContainer theme="colored" />
    </BrowserRouter>
  );
};

export default App;
