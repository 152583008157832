import { createSlice } from "@reduxjs/toolkit";
import produce from "immer";

const initialState = {
  providerlogged: false,
  providerUser: {},
  schedulerUser:{}
};

export const providerSlice = createSlice({
  name: "providerauthentication",
  initialState,
  reducers: {
    providerLoginSuccess: produce((state, { payload }) => {
      state.providerlogged = true;
      state.providerUser = payload;
    }),
    providerLoginFailure: produce((state) => {
      state.providerlogged = false;
      state.currentUser = null;
    }),
    schedulerLoginSuccess: produce((state, { payload }) => {
      state.schedulerUser = payload;
    }),
  },
});

export const {
  providerLoginSuccess,
  providerLoginFailure,
  schedulerLoginSuccess
} = providerSlice.actions;
export default providerSlice.reducer;
